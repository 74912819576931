import update from "immutability-helper";
import {
  getSkills,
  makeSkill,
  delSkill,
  getSkillsByLists,
  getSkillsByName
} from "../api/skillRequests";

const defaultState = {
  skills: [],
  availableSkills: []
};

const SET_SKILLS = "SET_SKILLS";
const SET_AVAILABLE = "SET_AVAILABLE";

//expected incoming format for lists is
//lists: {
// birthright: {},
// culture: {},
// primary: [{}, {}, {}],
//   secondary: [{}, {}, {}, {}, {}, {}],
//   archetype: [{}, {}],
//  monstrosity: [{}]
//}
export function getAvailableSkills(lists) {
  let listIds = [];
  if (lists.birthright.hasOwnProperty("id")) listIds.push(lists.birthright.id);

  if (lists.culture.hasOwnProperty("id")) listIds.push(lists.culture.id);

  if (lists.monstrosity.hasOwnProperty("id"))
    listIds.push(lists.monstrosity.id);

  lists.primary.forEach(function(item) {
    if (item.hasOwnProperty("id")) listIds.push(item.id);
  });
  lists.secondary.forEach(function(item) {
    if (item.hasOwnProperty("id")) listIds.push(item.id);
  });
  lists.archetype.forEach(function(item) {
    if (item.hasOwnProperty("id")) listIds.push(item.id);
  });
  return dispatch => {
    return getSkillsByLists(listIds)
      .then(response => dispatch(setAvailableSkills(response)))
      .catch(errors => console.log(errors));
  };
}

function setAvailableSkills(avail) {
  return {
    type: SET_AVAILABLE,
    avail
  };
}

export function fetchSkills() {
  return dispatch => {
    return getSkills().then(response => dispatch(setSkills(response)));
  };
}

export function fetchSkillsLike(skillname) {
  return dispatch => {
    return getSkillsByName(skillname).then(response =>
      dispatch(setSkills(response))
    );
  };
}

export function upsertSkill(skill) {
  return dispatch => {
    if (skill.id === 0) {
      delete skill.id;
    }
    return makeSkill(skill).then(response => {
      dispatch(fetchSkills());
    });
  };
}

export function deleteSkill(id) {
  return dispatch => {
    return delSkill(id).then(response => dispatch(fetchSkills()));
  };
}

function setSkills(skills) {
  return {
    type: SET_SKILLS,
    skills
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_SKILLS:
      return update(state, {
        skills: { $set: action.skills }
      });
    case SET_AVAILABLE:
      return update(state, {
        availableSkills: { $set: action.avail }
      });
    default:
      return state;
  }
}
