import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem/index";
import Select from "@material-ui/core/Select/Select";
import Button from "@material-ui/core/Button";
import GameAttendanceDialog from "./gameAttendanceDialog";
import { deleteAGameAttendance } from "../../reducers/gamesReducer";
import { fetchAllActivePlayers } from "../../reducers/playerReducer";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { getGameAttendance } from "../../api/gameRequests";
import { handlePrinting } from "../../actions/commonActions";

class SummaryDialog extends Component {
  constructor(props) {
    super(props);
    this.props.fetchAllActivePlayers();
  }

  state = {
    openAttendanceEdit: false,
    blankAttend: {
      playername: "",
      playernumber: 0,
      playerid: 0,
      staffxp: 0,
      sptoxp: 0,
      gainsp: 0,
      spendsp: 0,
      spnotes: "",
      position: '',
      gameid: 1,
      characters: []
    },
    summary: []
  };

  summary() {
    return this.props.summary.map((player, row) => {
      return (
        <TableRow key={row}>
          <TableCell>{player.playernumber}</TableCell>
          <TableCell>{player.playername}</TableCell>
          <TableCell>
            <Select value={""} onChange={this.handleSelect.bind(this, row)}>
              <MenuItem value="edit">Edit Attendance</MenuItem>
              <MenuItem value="delete">Unattend</MenuItem>
              <MenuItem value="print">Print Sheet</MenuItem>
            </Select>
          </TableCell>
        </TableRow>
      );
    });
  }

  async setAttendingPlayer(playerid) {
    let reply = await getGameAttendance(this.props.gameid, playerid);
    this.setState({ blankAttend: reply, openAttendanceEdit: true });
  }

 async handleSelect(selectedRow, event) {
    switch (event.target.value) {
      case "edit":
       await this.setAttendingPlayer(this.props.summary[selectedRow].playerid);
        break;
      case "delete":
        this.setState({ open: false });
        this.props.deleteGameAttendance(
          this.props.gameid,
          this.props.summary[selectedRow].playerid
        );
        break;
      case "print":
       await handlePrinting(this.gatherCharIds(selectedRow));
        break;
      default:
        return alert("I should not happen");
    }
  }

  _gatherAllCharacterIds(){
    let charIds = [];
    this.props.summary.forEach(player => {
      player.characters.forEach(char => {
        charIds.push(char.charid);
      })
      }
    );

    return charIds;
  }

  gatherCharIds(selectedRow){
    let charIds = [];
    this.props.summary[selectedRow].characters.forEach(char => {
      charIds.push(char.charid)
    });
    return charIds
  }

  _newAttendButton() {
    this.setState({
      blankAttend: {
        playername: "",
        playernumber: 0,
        playerid: 0,
        staffxp: 0,
        gainsp: 0,
        spendsp: 0,
        spnotes: "",
        gameid: 1,
        playerattend: false,
        position: '',
        characters: []
      },
      openAttendanceEdit: true
    });
  }

  summaryDisplay() {
    return (
      <div>
        <Table className="SummaryEditTable" width={"600px"}>
          <TableBody>
            <TableRow>
              <TableCell>
                <Button
                  onClick={() => this._newAttendButton()}
                  variant={"contained"}
                  value={"new"}
                  color={"primary"}
                >
                  New Player Attendance
                </Button>
              </TableCell>
              <TableCell>
                <Button
                  onClick={() => handlePrinting(this._gatherAllCharacterIds())}
                  variant={"contained"}
                  color={"secondary"}
                >
                  Print All Sheets
                </Button>
              </TableCell>
            </TableRow>
            {this.summary()}
          </TableBody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} fullScreen>
          {this.summaryDisplay()}
          <DialogActions>
            <Button onClick={this.props.close} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
        {this.state.openAttendanceEdit && (
          <GameAttendanceDialog
            close={() => {
              this.setState({ openAttendanceEdit: false });
            }}
            open={this.state.openAttendanceEdit}
            attendance={this.state.blankAttend}
            gameid={this.props.gameid}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    gameAttendance: state.games.gameAttendance,
    summary: state.games.gameSummary
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteGameAttendance: function(gameid, playerid) {
      dispatch(deleteAGameAttendance(gameid, playerid));
    },
    fetchAllActivePlayers: function() {
      dispatch(fetchAllActivePlayers());
    }
  };
}

SummaryDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  gameid: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SummaryDialog);
