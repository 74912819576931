import { Table } from "@material-ui/core";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";
import TableBody from "@material-ui/core/TableBody";

const statsFieldsWithNumbers = ["totalhealth", "totalfocus", "strength", "powerpool", "totalxp"];

function runStatsWithNumbers(characterStats, workingField, activeCharacters) {
  if (characterStats.length > 0) {
    const field = workingField;
    const activeChars = activeCharacters;
    const charactersWithStat = characterStats.filter(char => char[field] > 0);

    const statArray = charactersWithStat.map(char => {
      return char[field];
    });

    const highestStat = Math.max(...statArray);
    const averageStat = Math.round(statArray.reduce((a, b) => a + b, 0) / statArray.length);
    const sortedStats = charactersWithStat.sort((a, b) => (a[field] < b[field] ? 1 : -1));
    const highestChars = [];
    const inum = charactersWithStat.length > 10 ? 10 : charactersWithStat.length;
    for (let i = 0; i < inum; i++) {
      highestChars.push({
        name: sortedStats[i].charname,
        stat: sortedStats[i][field],
        display: sortedStats[i].charname + ' ' + sortedStats[i][field]
      });
    }

    const lowestHighStat = highestChars[highestChars.length - 1].stat;

    for (const char of sortedStats) {
      if (char[field] === lowestHighStat && !highestChars.some(ele => ele.name === char.charname)) {
        highestChars.push({ name: char.charname, stat: char[field], display: char.charname + ' ' + char[field] })
      }
    }

    const marketShare = ((charactersWithStat.length / activeChars) * 100).toFixed(2);

    return {
      highest: highestStat,
      average: averageStat,
      topChars: highestChars,
      marketShare: marketShare
    };
  } else {
    return {
      highest: 0,
      average: 0,
      topChars: [],
      marketShare: 0
    };
  }
}

function makeStatObjWithNumbers(characterStats, fields, activeCharacters) {
  const statObj = {};
  const stats = characterStats;
  const charFields = fields;
  const activeChars = activeCharacters;
  for (const field of charFields) {
    statObj[field] = runStatsWithNumbers(stats, field, activeChars);
  }

  return statObj;
}

export function characterStats(characterStats, activeCharacters) {
  const fieldedStats = makeStatObjWithNumbers(characterStats, statsFieldsWithNumbers, activeCharacters);

  return (
    <Table>
      <TableBody>
        <TableRow style={{ backgroundColor: "CornflowerBlue" }}>
          <TableCell>Average Health: {fieldedStats.totalhealth.average}</TableCell>
          <TableCell>Average Focus: {fieldedStats.totalfocus.average}</TableCell>
          <TableCell>Average Strength: {fieldedStats.strength.average}</TableCell>
          <TableCell>Average Xp: {fieldedStats.totalxp.average}</TableCell>
          <TableCell>Have a powerpool: {fieldedStats.powerpool.marketShare}%</TableCell>
        </TableRow>
        <TableRow style={{ backgroundColor: "DarkSlateGrey" }}>
          <TableCell>Most Health Characters: </TableCell>
          <TableCell>Most Focus Characters: </TableCell>
          <TableCell>Most Strength Characters: </TableCell>
          <TableCell>Most XP Characters: </TableCell>
          <TableCell>Most Powerpool Characters: </TableCell>
        </TableRow>
        {statTable(
          fieldedStats.totalhealth.topChars.sort((a, b) => (a.stat < b.stat) ? 1 : -1),
          fieldedStats.totalfocus.topChars.sort((a, b) => (a.stat < b.stat) ? 1 : -1),
          fieldedStats.strength.topChars.sort((a, b) => (a.stat < b.stat) ? 1 : -1),
          fieldedStats.totalxp.topChars.sort((a, b) => (a.stat < b.stat) ? 1 : -1),
          fieldedStats.powerpool.topChars.sort((a, b) => (a.stat < b.stat) ? 1 : -1)
        )}
      </TableBody>
    </Table>
  );
}

function statTable(highHealthChar, highFocusChar, highStrengthChar, highXpChar, highPowerPoolChar) {
  let table = [];
  const highLength = [highFocusChar.length, highHealthChar.length, highStrengthChar.length, highPowerPoolChar.length];
  for (let i = 0; i < Math.max(...highLength); i++) {
    table.push(
      <TableRow key={i}>
        <TableCell>{highHealthChar.length > i && highHealthChar[i].display}</TableCell>
        <TableCell>{highFocusChar.length > i && highFocusChar[i].display}</TableCell>
        <TableCell>{highStrengthChar.length > i && highStrengthChar[i].display}</TableCell>
        <TableCell>{highXpChar.length > i && highXpChar[i].display}</TableCell>
        <TableCell>{highPowerPoolChar.length > i && highPowerPoolChar[i].display}</TableCell>
      </TableRow>
    );
  }

  return table;
}
