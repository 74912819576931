import update from "immutability-helper";
import {
  getViceVirtues,
  updateViceVirtue,
  delViceVirtue
} from "../api/viceRequests";

const defaultState = {
  vices: []
};

const SET_VIRTUES = "SET_VIRTUES";

export function fetchVices() {
  return dispatch => {
    return getViceVirtues().then(response => dispatch(setVirtues(response)));
  };
}

export function editVice(vicevirtue) {
  return dispatch => {
    if (vicevirtue.id === 0) {
      delete vicevirtue.id;
    }
    return updateViceVirtue(vicevirtue).then(response => {
        dispatch(fetchVices());
      }
    );
  };
}

export function deleteVice(id) {
  return dispatch => {
    return delViceVirtue(id).then(response => dispatch(fetchVices()));
  };
}

function setVirtues(virtues) {
  return {
    type: SET_VIRTUES,
    virtues
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_VIRTUES:
      return update(state, {
        vices: { $set: action.virtues }
      });
    default:
      return state;
  }
}
