import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Table from "@material-ui/core/Table";
import React from "react";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import AnalyticsDialog from "./analyticsDialog";

export function allSkillsAnalytics(skillsAnalytics, activeCharacters) {

  let uniqueSkill = {};

  for (const skill of skillsAnalytics) {
    if (!uniqueSkill.hasOwnProperty(skill.skillname)) {
      uniqueSkill[skill.skillname] = { percent: 0, characters: [] };
    }
    uniqueSkill[skill.skillname].characters.push({ character: skill.charname, player: skill.playername });
  }

  Object.keys(uniqueSkill).forEach(key => {
    uniqueSkill[key].percent = ((uniqueSkill[key].characters.length/activeCharacters) * 100).toFixed(2)
  });

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>
            Skill Name
          </TableCell>
          <TableCell>
            Characters With
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {listSkills(uniqueSkill)}
      </TableBody>
    </Table>
  )
}

function listSkills(skills){
  const rows = [];

  Object.keys(skills).sort().forEach((key, index) => {
    rows.push(
      <TableRow key={index}>
        <TableCell>
          <AnalyticsDialog
            field={key}
            characters={skills[key].characters}
          />
        </TableCell>
        <TableCell>
          {skills[key].percent}%
        </TableCell>
      </TableRow>
    )
  });

  return rows;
}