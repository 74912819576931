import update from "immutability-helper";
import {
  delCharacter,
  getCurrentCharacters,
  makeNewCharacter,
  updateACharacter
} from "../api/characterRequests";

const defaultState = {
  currentPlayerCharacters: [{totalsp: 0}],
  characterEditable: false,
  updatingCharacter: {}
};

const FAILURE = "FAILURE";
const SET_CURRENT_CHARACTERS = "SET_CURRENT_CHARACTERS";
const SET_UPDATING_CHARACTER = "SET_UPDATING_CHARACTER";

function failure(errors) {
  return {
    type: FAILURE,
    user: {
      errors: errors["json"]
        ? errors.json.errors
        : {
            error: {
              message: "I failed"
            }
          }
    }
  };
}

export function createNewCharacter(playerid) {
  return dispatch => {
    return makeNewCharacter(playerid).then(response =>
      dispatch(fetchCurrentCharacters(playerid))
    );
  };
}

export function setUpdatingCharacter(update) {
  return dispatch => {
    dispatch(updateCharacterReceive(update));
  };
}

function updateCharacterReceive(update) {
  return {
    type: SET_UPDATING_CHARACTER,
    update
  };
}

export function updateCharacter(charInfo) {
  return dispatch => {
    if (charInfo.id === 0) {
      delete charInfo.id;
    }
    return updateACharacter(charInfo)
      .then(response => {
        dispatch(fetchCurrentCharacters(charInfo.playerid));
        dispatch(setUpdatingCharacter({}));
      })
      .catch(errors => {
        dispatch(failure(errors));
      });
  };
}

export function deleteCharacter(charId, playerId) {
  return dispatch => {
    return delCharacter(charId).then(response => {
      dispatch(fetchCurrentCharacters(playerId));
    });
  };
}

export function setCurrentCharacters(characters) {
  return {
    type: SET_CURRENT_CHARACTERS,
    characters
  };
}

export function fetchCurrentCharacters(playerID) {
  return dispatch => {
    return getCurrentCharacters(playerID)
      .then(response => {
        if (response.success === false) {
          dispatch(setCurrentCharacters([]));
        } else {
          dispatch(setCurrentCharacters(response));
        }
      })
      .catch(errors => dispatch(failure(errors)));
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_CURRENT_CHARACTERS:
      return update(state, {
        isFetchingPlayers: { $set: false },
        currentPlayerCharacters: { $set: action.characters }
      });
    case SET_UPDATING_CHARACTER:
      return update(state, {
        updatingCharacter: { $set: action.update }
      });
    default:
      return state;
  }
}
