import {combineReducers} from "redux";
import playerReducer from "./playerReducer";
import characterReducer from "./characterReducer";
import vicevirtueReducer from "./vicevirtuesReducer";
import gamesReducer from "./gamesReducer";
import listsReducer from "./listsReducer";
import skillsReducer from "./skillsReducer";

const rootReducer = combineReducers({
  players: playerReducer,
  character: characterReducer,
  vices: vicevirtueReducer,
  games: gamesReducer,
  lists: listsReducer,
  skills: skillsReducer
});
export default rootReducer;
