import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import MenuItem from "@material-ui/core/MenuItem/index";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select/Select";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import { editVice } from "../../reducers/vicevirtuesReducer";
import { validateSubmission } from "../../actions/validation";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";

class ViceDialog extends Component {
  constructor(props) {
    super(props);
    this.state ={
      vice: {
        id: props.vice.id,
        name: props.vice.name,
        quality: props.vice.quality
      }
    };
  }

  _onSubmit() {
    const reqProps = ["id", "name", "quality"];
    if (
      validateSubmission(reqProps, this.state.vice) &&
      Boolean(this.state.vice.name) &&
      Boolean(this.state.vice.quality)
    ) {
      this.props.editVice(this.state.vice);
      this.props.close();
    } else {
      alert("Some required fields are missing");
    }
  }

  handleChange(name, value) {
    let editedVice = this.state.vice;
    editedVice[name] = value;
    this.setState({ vice: editedVice });
  }
  
  vice() {
    return (
      <Table className="ViceEditTable" width={"600px"}>
        <TableBody>
          <TableRow>
            <TableCell colSpan={2}>
              <TextField
                required
                label={"Name"}
                value={this.state.vice.name}
                onChange={e => this.handleChange("name", e.target.value)}
              />
            </TableCell>
            <TableCell colSpan={2}>
              <InputLabel>Quality</InputLabel>
              <Select
                value={this.state.vice.quality}
                onChange={e =>
                  this.handleChange("quality", e.target.value)
                }
              >
                <MenuItem value="Vice">Vice</MenuItem>
                <MenuItem value="Virtue">Virtue</MenuItem>
              </Select>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} fullScreen>
          {this.vice()}
          <DialogActions>
            <Button onClick={this.props.close} color="secondary">
              Cancel
            </Button>
            <Button onClick={this._onSubmit.bind(this)} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapDispatchToProps(dispatch) {
  return {
    editVice: function(vice) {
      dispatch(editVice(vice));
    }
  };
}

ViceDialog.propTypes = {
  vice: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(ViceDialog);
