import update from "immutability-helper";
import {
  getLists,
  upsertList,
  delList,
  getListsByType,
  getAccessLists
} from "../api/listsRequests";

const defaultState = {
  lists: [],
  listType: {
    birthright: [],
    culture: [],
    list: [],
    archetype: [],
    monstrosity: []
  }
};

const SET_LISTS = "SET_LISTS";
const SET_LIST_TYPE = "SET_LIST_TYPE";

export function fetchLists() {
  return dispatch => {
    return getLists().then(response => dispatch(setLists(response)));
  };
}

export function fetchAccessLists() {
  return dispatch => {
    return getAccessLists().then(response => dispatch(setLists(response)));
  };
}

export function fetchListsByType() {
  return dispatch => {
    return getListsByType().then(response =>
      dispatch(setListType(response))
    );
  };
}

export function editList(list) {
  return dispatch => {
    if (list.id === 0) {
      delete list.id;
    }
    return upsertList(list).then(response => {
      dispatch(fetchLists());
    });
  };
}

export function deleteList(id) {
  return dispatch => {
    return delList(id).then(response => dispatch(fetchLists()));
  };
}

function setLists(lists) {
  return {
    type: SET_LISTS,
    lists
  };
}

function setListType(listType) {
  return {
    type: SET_LIST_TYPE,
    listType
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case SET_LISTS:
      return update(state, {
        lists: { $set: action.lists }
      });
    case SET_LIST_TYPE:
      return update(state, {
        listType: { $set: action.listType }
      });
    default:
      return state;
  }
}
