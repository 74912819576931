import fetchJSON from "./fetchJSON";
import { queryCheck } from "../actions/validation";

export function getSkills(skillName, interval) {
  const options = {
    method: "GET"
  };
  return fetchJSON("/analytics/skills/"+interval+'/'+skillName, options).then(results => {
    return queryCheck(results);
  });
}

export function getCharacterStats(interval) {
  const options = {
    method: "GET"
  };
  return fetchJSON("/analytics/stats/"+interval, options).then(results => {
    return queryCheck(results);
  });
}

export function getCharacterCount(interval) {
  const options = {
    method: "GET"
  };
  return fetchJSON("/analytics/count/"+interval, options).then(results => {
    return queryCheck(results);
  });
}

export function getSkillNames() {
  const options = {
    method: "GET"
  };

  return fetchJSON("/analytics/skillnames/", options).then(results => {
    return queryCheck(results);
  });
}

export function getLists(listName, interval){
  const options = {
    method: "GET"
  };
  return fetchJSON("/analytics/lists/"+interval+'/'+listName, options).then(results => {
    return queryCheck(results);
  });
}