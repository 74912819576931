import fetchJSON from "./fetchJSON";
import { queryCheck } from "../actions/validation";

export function updateViceVirtue(vicevirtue) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(vicevirtue)
  };
  return fetchJSON("/vicevirtue", options).then(results => {
    return queryCheck(results);
  });
}

export function delViceVirtue(id) {
  const options = {
    method: "DELETE"
  };
  return fetchJSON("/vicevirtue/" + id, options).then(results => {
    return queryCheck(results);
  });
}

export function getViceVirtues() {
  const options = {
    method: "GET"
  };
  return fetchJSON("/vicevirtue", options).then(results => {
    return queryCheck(results);
  });
}
