import fetchJSON from "./fetchJSON";
import { queryCheck } from "../actions/validation";

export function getAllGames() {
  const options = {
    method: "GET"
  };
  return fetchJSON("/games", options).then(results => {
    return queryCheck(results);
  });
}

export function getSingleGame(id) {
  const options = {
    method: "GET"
  };
  return fetchJSON("/games/" + id, options).then(results => {
    return queryCheck(results);
  });
}

export function updateAGame(details) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(details)
  };
  return fetchJSON("/games", options).then(results => {
    return queryCheck(results);
  });
}

export function delGame(id) {
  const options = {
    method: "DELETE"
  };
  return fetchJSON("/games/delete/" + id, options).then(results => {
    return queryCheck(results);
  });
}

export function updateAGameAttendance(details) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(details)
  };

  return fetchJSON("/attendgame/update", options).then(results => {
    return queryCheck(results);
  });
}

export function getGameAttendance(gameid, playerid) {
  const options = {
    method: "GET"
  };

  return fetchJSON(
    "/attendgame/" + gameid + "/" + playerid + "/summary",
    options
  ).then(results => {
    return queryCheck(results);
  });
}

export function removeGameAttendance(gameid, playerid) {
  const options = {
    method: "DELETE",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    }
  };

  return fetchJSON("/attendgame/" + gameid + "/" + playerid, options).then(
    results => {
      return results;
    }
  );
}

export function getGameSummary(gameid) {
  const options = {
    method: "GET"
  };
  return fetchJSON("/attendgame/" + gameid + "/summary", options).then(
    results => {
      return queryCheck(results);
    }
  );
}
