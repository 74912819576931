import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Select from "@material-ui/core/Select/Select";
import { dateToSql } from "../../actions/commonActions";
import {
  fetchAllGames,
  fetchGameSummary,
  deleteGame
} from "../../reducers/gamesReducer";
import MenuItem from "@material-ui/core/MenuItem/index";
import GameDialog from "./gameDialog";
import Button from "@material-ui/core/Button/Button";
import SummaryDialog from "./gameSummaryDialog";

class GamesList extends Component {
  constructor(props) {
    super(props);
    this.props.fetchAllGames();
  }

  state = {
    height: "400px",
    width: "200px",
    openGames: false,
    openGamesSummary: false,
    blankGame: {
      gamedate: dateToSql(new Date()),
      locationname: "",
      locationaddress: "",
      gamenumber: 0,
      basexp: "4",
      maxbuyxp: "3"
    },
    selectedGameId: 0
  };

  gamesList() {
    return this.props.allGames.map((game, key) => {
      return (
        <TableRow key={key}>
          <TableCell>{game.gamenumber}</TableCell>
          <TableCell> {game.gamedate.toString().split("T")[0]}</TableCell>
          <TableCell>{game.locationname}</TableCell>
          <TableCell>{game.locationaddress}</TableCell>
          <TableCell>{game.basexp}</TableCell>
          <TableCell>{game.maxbuyxp}</TableCell>
          <TableCell>
            <Select value={""} onChange={this.handleSelect.bind(this, key)}>
              <MenuItem value="edit">Edit</MenuItem>
              <MenuItem value="delete">Delete</MenuItem>
              <MenuItem value="summary">Summary</MenuItem>
            </Select>
          </TableCell>
        </TableRow>
      );
    });
  }

  handleSelect(selectedRow, event) {
    switch (event.target.value) {
      case "summary":
        this.setState({
          selectedGame: this.props.allGames[selectedRow],
          openGamesSummary: true,
          selectedGameId: this.props.allGames[selectedRow].id
        },this.props.fetchGameSummary(this.props.allGames[selectedRow].id));
        break;
      case "edit":
        this.setState({ blankGame: this.props.allGames[selectedRow] });
        this.setState({ openGames: true });
        break;
      case "delete":
        if (window.confirm("Delete this game?")) {
          this.props.deleteGame(this.props.allGames[selectedRow].id);
        }
        break;
      default:
        return alert("I should not happen");
    }
  }

  newGameBtn() {
    this.setState({
      blankGame: {
        gamedate: "2019-01-01",
        locationname: "",
        locationaddress: "",
        gamenumber: 0,
        basexp: "4",
        maxbuyxp: "3"
      }
    });
    this.setState({ openGames: true });
  }

  render() {
    return (
      <div>
        <Table className="gamesTable">
          <TableHead>
            <TableRow>
              <TableCell>{"Game Number"}</TableCell>
              <TableCell>{"Date"}</TableCell>
              <TableCell>{"Location"}</TableCell>
              <TableCell>{"Address"}</TableCell>
              <TableCell>{"Base XP"}</TableCell>
              <TableCell>{"Max Buy XP"}</TableCell>
              <TableCell>
                <Button
                  onClick={this.newGameBtn.bind(this)}
                  variant={"contained"}
                  value={"new"}
                >
                  New Game
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.gamesList()}</TableBody>
        </Table>
        {this.state.openGames && (
          <GameDialog
            close={() => {
              this.setState({ openGames: false });
            }}
            open={this.state.openGames}
            game={this.state.blankGame}
          />
        )}
        {this.state.openGamesSummary && (
          <SummaryDialog
            close={() => {
              this.setState({ openGamesSummary: false });
            }}
            open={this.state.openGamesSummary}
            gameid={this.state.selectedGameId}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allGames: state.games.allGames
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllGames: function() {
      dispatch(fetchAllGames());
    },
    fetchGameSummary: function(gameid) {
      dispatch(fetchGameSummary(gameid));
    },
    deleteGame: function(gameid) {
      dispatch(deleteGame(gameid));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GamesList);
