import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { editList } from "../../reducers/listsReducer";
import { validateSubmission } from "../../actions/validation";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";

class ListDialog extends Component {

  constructor(props) {
    super(props);
    this.state = {
      list: {
        id: props.list.id,
        name: props.list.name,
        type: props.list.type
      }
    };
  }

  handleChange(name, value) {
    let editedList = this.state.list;
    editedList[name] = value;
    this.setState({ list: editedList });
  }

  _onSubmit() {
    const reqProps = ["id", "name", "type"];
    if (
      validateSubmission(reqProps, this.state.list) &&
      Boolean(this.state.list.name)
    ) {
      this.props.editList(this.state.list);
      this.props.close();
    } else {
      alert("Some required fields are missing");
    }
  }


  list() {
    return (
      <Table className="ListEditTable" width={"600px"}>
        <TableBody>
          <TableRow>
            <TableCell colSpan={2}>
              <TextField
                required
                label={"Name"}
                value={this.state.list.name}
                onChange={e => this.handleChange("name", e.target.value)}
              />
            </TableCell>
            <TableCell colSpan={2}>
              <InputLabel>Type</InputLabel>
              <Select
                value={this.state.list.type}
                onChange={e => this.handleChange("type", e.target.value)}
              >
                <MenuItem value="birthright">Birthright</MenuItem>
                <MenuItem value="culture">Culture</MenuItem>
                <MenuItem value="list">List</MenuItem>
                <MenuItem value="monstrosity">Monstrosity</MenuItem>
                <MenuItem value="archetype">Archetype</MenuItem>
              </Select>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} fullScreen>
          {this.list()}
          <DialogActions>
            <Button onClick={this.props.close} color="secondary">
              Cancel
            </Button>
            <Button onClick={this._onSubmit.bind(this)} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}


function mapDispatchToProps(dispatch) {
  return {
    editList: function(list) {
      dispatch(editList(list));
    }
  };
}

ListDialog.propTypes = {
  list: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default connect(
  null,
  mapDispatchToProps
)(ListDialog);
