import fetchJSON from "./fetchJSON";
import { queryCheck } from "../actions/validation";

export function upsertList(list) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(list)
  };
  return fetchJSON("/lists/", options).then(results => {
    return queryCheck(results);
  });
}

export function delList(id) {
  const options = {
    method: "DELETE"
  };
  return fetchJSON("/lists/" + id, options).then(results => {
    return queryCheck(results);
  });
}

export function getLists() {
  const options = {
    method: "GET"
  };
  return fetchJSON("/lists", options).then(results => {
    return queryCheck(results);
  });
}

export function getAccessLists() {
  const options = {
    method: "GET"
  };
  return fetchJSON("/lists/accessLists", options).then(results => {
    return queryCheck(results);
  });
}

export function getListsByType() {
  const options = {
    method: "GET"
  };
  return fetchJSON("/lists/bytype", options).then(results => {
    return queryCheck(results);
  });
}
