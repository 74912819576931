import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TableHead from "@material-ui/core/TableHead";
import Checkbox from "@material-ui/core/Checkbox";
import {
  createNewPlayer,
  fetchAllActivePlayers,
  fetchAllActivePlayersLike
} from "../../reducers/playerReducer";
import Button from "@material-ui/core/Button";
import { dateToSql } from "../../actions/commonActions";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";
import PlayerDialog from "./playerDialog";
import TextField from "@material-ui/core/TextField";

class PlayersList extends Component {
  constructor(props) {
    super(props);
    //Pulls all players into props via reducer to map them via statetoprops
    this.props.fetchAllActivePlayers();
  }

  state = {
    showBanned: false,
    open: false,
    //this is used to pass to the player if brand new
    blankPlayer: {
      startdate: dateToSql(new Date()),
      ban: false,
      phonenumber: "",
      emerwaiverdate: dateToSql(new Date()),
      photowaiverdate: dateToSql(new Date()),
      photowaiversigned: false,
      emerwaiversigned: false,
      adminnotes: "",
      discipline: "",
      playernotes: "",
      specrestrict: "",
      healthneeds: "",
      firstname: "",
      lastname: "",
      email: "",
      id: 0,
      playernumber: "",
      reference: 0,
      position: "",
      staff: false
    },
    playerSearch: ""
  };

  playersList() {
    return this.props.allActivePlayers.map((player, row) => {
      return (
        <TableRow key={row}>
          <TableCell>{player.playernumber}</TableCell>
          <TableCell>
            {" "}
            {player.firstname} {player.lastname}
          </TableCell>
          <TableCell>{player.email}</TableCell>
          <TableCell>{true === player.ban ? "true" : "false"}</TableCell>
          <TableCell>{player.adminnotes}</TableCell>
          <TableCell>{player.specrestrict}</TableCell>
          <TableCell>
            <Select value={""} onChange={this.handleSelect.bind(this, row)}>
              <MenuItem value="edit">Edit</MenuItem>
              <MenuItem value="ban">Ban</MenuItem>
            </Select>
          </TableCell>
        </TableRow>
      );
    });
  }

  handleSelect(selectedRow, event) {
    //multiple choice switch statement based on the value of the menuitem selected
    switch (event.target.value) {
      case "edit":
        //changes blank player state to the player selected
        this.setState({
          blankPlayer: this.props.allActivePlayers[selectedRow],
          open: true
        });
        break;
      case "ban":
        alert("ban");
        break;
      default:
        return alert("I should not happen");
    }
  }

  showBanned() {
    this.setState(oldState => {
      return {
        showBanned: !oldState.showBanned
      };
    });
  }

  handlePlayerChange(value) {
    //dialog gets this function, which updates the state and values
    this.setState(
      { playerSearch: value },
      this.props.fetchAllActivePlayersLike(value)
    );
  }

  _makeNewPlayer() {
    this.setState({ playerSearch: "New" });
    this.props.makeNewPlayer();
  }

  render() {
    return (
      <div>
        <TextField
          value={this.state.playerSearch}
          label={"Find Player"}
          onChange={e => this.handlePlayerChange(e.target.value)}
        />
        <Table className="playersTable">
          <TableHead>
            <TableRow>
              <TableCell>{"Player Number"}</TableCell>
              <TableCell>{"Name"}</TableCell>
              <TableCell>{"Email"}</TableCell>
              <TableCell>{"Ban"}</TableCell>
              <TableCell>{"Admin Notes"}</TableCell>
              <TableCell>{"Special Restrictions"}</TableCell>
              <TableCell>
                <Button
                  onClick={() => this._makeNewPlayer()}
                  variant={"contained"}
                  value={"new"}
                >
                  New Player
                </Button>
              </TableCell>
              <TableCell>
                Show Banned?
                <Checkbox
                  label="Show Banned Players?"
                  checked={this.state.showBanned}
                  onChange={this.showBanned.bind(this)}
                />
              </TableCell>
              <TableCell />
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>{this.playersList()}</TableBody>
        </Table>
        {this.state.open && (
          <PlayerDialog
            close={() => {
              this.setState({ open: false });
            }}
            open={this.state.open}
            player={this.state.blankPlayer}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    //this is the state of the reducer
    allActivePlayers: state.players.allActivePlayers
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllActivePlayers: function() {
      dispatch(fetchAllActivePlayers());
    },
    fetchAllActivePlayersLike: function(playername) {
      dispatch(fetchAllActivePlayersLike(playername));
    },
    makeNewPlayer: function() {
      dispatch(createNewPlayer());
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayersList);
