import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  deleteVice,
  fetchVices
} from "../../reducers/vicevirtuesReducer";
import MenuItem from "@material-ui/core/MenuItem/index";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import ViceDialog from "./viceDialog";

class VicesList extends Component {
  constructor(props) {
    super(props);
    this.props.fetchVices();
  }

  state = {
    open: false,
    blankVice: {
      id: 0,
      name: "",
      quality: ""
    }
  };

  viceList() {
    return this.props.allVices.map((vice, row) => {
      return (
        <TableRow key={row}>
          <TableCell>{vice.name}</TableCell>
          <TableCell>{vice.quality}</TableCell>
          <TableCell>
            <Select value={""} onChange={this.handleSelect.bind(this, row)}>
              <MenuItem value="edit">Edit</MenuItem>
              <MenuItem value="delete">Delete</MenuItem>
            </Select>
          </TableCell>
        </TableRow>
      );
    });
  }

  handleSelect(selectedRow, event) {
    switch (event.target.value) {
      case "edit":
        this.setState({ blankVice: this.props.allVices[selectedRow] });
        this.setState({ open: true });
        break;
      case "delete":
        if(window.confirm("Delete this vice/virtue?") === true) {
          this.props.deleteVice(this.props.allVices[selectedRow].id);
        }
        break;
      default:
        return alert("I should not happen");
    }
  }

  newVice() {
    this.setState({
      blankVice: {
        id: 0,
        name: "",
        quality: ""
      }
    });
    this.setState({ open: true });
  }

  render() {
    return (
      <div>
        <Table className="vicesTable">
          <TableHead>
            <TableRow>
              <TableCell>Vice Name</TableCell>
              <TableCell>Quality</TableCell>
              <TableCell>
                <Button
                  onClick={this.newVice.bind(this, "new")}
                  variant={"contained"}
                  value={"new"}
                >
                  New Vice/Virtue
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.viceList()}</TableBody>
        </Table>
        { this.state.open && <ViceDialog
          close={() => {
            this.setState({ open: false });
          }}
          open={this.state.open}
          vice={this.state.blankVice}
        />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allVices: state.vices.vices
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchVices: function() {
      dispatch(fetchVices());
    },
    deleteVice: function(viceid) {
      dispatch(deleteVice(viceid));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VicesList);
