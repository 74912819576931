import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { menuNumbers } from "../../actions/commonActions";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import Select from "@material-ui/core/Select/Select";
import { MuiPickersUtilsProvider, DatePicker } from "material-ui-pickers";
import "date-fns";
import DateFnsUtils from "@date-io/date-fns";
import moment from "moment";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { updateGame } from "../../reducers/gamesReducer";

class GameDialog extends Component {
  constructor(props) {
    super(props);
    
    this.state = {
      game: {
        gamedate: props.game.gamedate,
        locationname: props.game.locationname,
        locationaddress: props.game.locationaddress,
        gamenumber: props.game.gamenumber,
        basexp: props.game.basexp,
        maxbuyxp: props.game.maxbuyxp
      },
      height: "500px",
      width: "300px"
    };
  }


  _handleChange(name, value) {
    let editedGame = this.state.game;
    if (name === "gamedate") {
      editedGame[name] = moment(value).format("YYYY-MM-DD");
    } else {
      editedGame[name] = value;
    }
    this.setState({ game: editedGame });
  }

  _onSubmit() {
    const game = this.state.game;
    if (game.id === 0) {
      delete game.id;
    }
    this.props.updateGame(game);
    this.props.close();
  }
  
  game() {
    return (
      <Table className="GameEditTable">
        <TableBody>
          <TableRow>
            <TableCell>
              <TextField
                required
                value={this.state.game.gamenumber}
                label={"Game Number"}
                type="number"
                onChange={e =>
                  this._handleChange("gamenumber", e.target.value)
                }
              />
            </TableCell>
            <TableCell>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <DatePicker
                  value={this.state.game.gamedate}
                  label={"Date"}
                  onChange={e => this._handleChange("gamedate", e)}
                />
              </MuiPickersUtilsProvider>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField
                value={this.state.game.locationname}
                label={"Location"}
                onChange={e =>
                  this._handleChange("locationname", e.target.value)
                }
              />
            </TableCell>
            <TableCell>
              <TextField
                defaultValue={this.state.game.locationaddress}
                label={"Address"}
                multiline
                rows="4"
                onChange={e =>
                  this._handleChange("locationaddress", e.target.value)
                }
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <InputLabel>Base XP</InputLabel>
              <Select
                value={this.state.game.basexp}
                onChange={e =>
                  this._handleChange("basexp", e.target.value)
                }
              >
                {menuNumbers(10)}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Max Buy XP</InputLabel>
              <Select
                value={this.state.game.maxbuyxp}
                onChange={e =>
                  this._handleChange("maxbuyxp", e.target.value)
                }
              >
                {menuNumbers(10)}
              </Select>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }


  render() {
    return (
      <div>
        <Dialog open={this.props.open} fullScreen>
          {this.game()}
          <DialogActions>
            <Button onClick={this.props.close} color="secondary">
              Cancel
            </Button>
            <Button onClick={this._onSubmit.bind(this)} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

GameDialog.propTypes = {
  game: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

function mapDispatchToProps(dispatch) {
  return {
    updateGame: function(details) {
      dispatch(updateGame(details));
    }
  };
}

export default connect(
  null,
  mapDispatchToProps
)(GameDialog);
