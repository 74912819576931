import fetchJSON from "./fetchJSON";
import { queryCheck } from "../actions/validation";

export function makeSkill(skill) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(skill)
  };
  return fetchJSON("/skills/", options).then(results => {
    return queryCheck(results);
  });
}

export function delSkill(id) {
  const options = {
    method: "DELETE"
  };
  return fetchJSON("/skills/" + id, options).then(results => {
    return queryCheck(results);
  });
}

export function getSkills() {
  const options = {
    method: "GET"
  };
  return fetchJSON("/skills", options).then(results => {
    return queryCheck(results);
  });
}

export function getSkillsByName(skillname) {
  const options = {
    method: "GET"
  };
  return fetchJSON("/skills/like?search=" + skillname, options).then(
    results => {
      return queryCheck(results);
    }
  );
}

export function getSkillsByLists(lists) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(lists)
  };

  return fetchJSON("/skills/available", options).then(results => {
    return queryCheck(results);
  });
}
