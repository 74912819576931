import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import {
  deleteSkill,
  fetchSkills,
  fetchSkillsLike
} from "../../reducers/skillsReducer";
import MenuItem from "@material-ui/core/MenuItem/index";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import SkillDialog from "./skillDialog";
import TextField from "@material-ui/core/TextField";

class SkillsList extends Component {
  constructor(props) {
    super(props);
    this.props.fetchSkills();
  }

  state = {
    open: false,
    blankSkill: {
      id: 0,
      skillname: "",
      listid: 0,
      xpcost: 0,
      focuscost: 0,
      type: "passive",
      bonushp: 0,
      bonusfp: 0,
      focusrecovery: 0,
      powerpool: 0,
      strength: 0
    },
    skillSearch: ""
  };

  skillsList() {
    return this.props.allSkills.map((skill, row) => {
      return (
        <TableRow key={row}>
          <TableCell>{skill.skillname}</TableCell>
          <TableCell> {skill.listname}</TableCell>
          <TableCell>
            <Select value={""} onChange={this.handleSelect.bind(this, row)}>
              <MenuItem value="edit">Edit</MenuItem>
              <MenuItem value="delete">Delete</MenuItem>
            </Select>
          </TableCell>
        </TableRow>
      );
    });
  }

  handleSelect(selectedRow, event) {
    switch (event.target.value) {
      case "edit":
        this.setState({
          blankSkill: this.props.allSkills[selectedRow],
          open: true
        });
        break;
      case "delete":
        if(window.confirm("Delete this skill?") === true) {
          this.props.deleteSkill(this.props.allSkills[selectedRow].id);
        }
        break;
      default:
        return alert("I should not happen");
    }
  }

  newSkillBtn() {
    this.setState({
      blankSkill: {
        id: 0,
        skillname: "",
        listid: 0,
        xpcost: 0,
        focuscost: 0,
        type: "passive",
        bonushp: 0,
        bonusfp: 0,
        focusrecovery: 0,
        powerpool: 0,
        strength: 0
      },
      open: true
    });
  }

  handleChange(value) {
    //dialog gets this function, which updates the state and values
    this.setState({ skillSearch: value }, this.props.fetchSkillsLike(value));
  }

  render() {
    return (
      <div>
        <TextField
          value={this.state.skillSearch}
          label={"Find Skill"}
          onChange={e => this.handleChange(e.target.value)}
        />
        <Table className="skillsTable">
          <TableHead>
            <TableRow>
              <TableCell>Skill name</TableCell>
              <TableCell>List</TableCell>
              <TableCell>
                <Button
                  onClick={this.newSkillBtn.bind(this)}
                  variant={"contained"}
                  value={"new"}
                >
                  New Skill
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.skillsList()}</TableBody>
        </Table>
        {this.state.open && (
          <SkillDialog
            close={() => {
              this.setState({ open: false });
            }}
            open={this.state.open}
            skill={this.state.blankSkill}
          />
        )}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allSkills: state.skills.skills
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchSkills: function() {
      dispatch(fetchSkills());
    },
    fetchSkillsLike: function(skillname) {
      dispatch(fetchSkillsLike(skillname));
    },
    deleteSkill: function(skillid) {
      dispatch(deleteSkill(skillid));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkillsList);
