import Button from "@material-ui/core/Button";
import { Dialog, DialogActions, DialogContent, makeStyles, Table } from "@material-ui/core";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import React from "react";

const useStyles = makeStyles(theme => ({
  paper: {
    position: "absolute",
    width: 400,
    backgroundColor: theme.palette.background.paper,
    border: "2px solid #000",
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3)
  }
}));

const AnalyticsDialog = props => {

  const classes = useStyles();
  const [open, setOpen] = React.useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <div>
      <Button varian={"outlined"} color={"secondary"} onClick={handleOpen}>
        {props.field}
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        fullWidth={true}
        maxWidth={"sm"}
        classes={{paper: classes.paper}}
      >
        <DialogContent>
          <Table>
            <TableBody>
              {props.characters.sort((a,b) => a.character.localeCompare(b.character)).map((char, index) => {
                return (
                  <TableRow key={index}>
                    <TableCell>
                      {char.character}
                    </TableCell>
                    <TableCell>
                      {char.player}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color={"primary"}>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default AnalyticsDialog;