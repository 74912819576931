import { Table } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableBody from "@material-ui/core/TableBody";
import React from "react";
import AnalyticsDialog from "./analyticsDialog";

const sortedListTypes = ["birthright", "culture", "list", "archetype", "monstrosity"];

export function listAnalytics(listAnalytics, activeCharacters) {

  let sortedLists = {};

  for (const character of listAnalytics) {
    if (!sortedLists.hasOwnProperty(character.type)) {
      sortedLists[character.type] = { [character.name]: [] };
    } else if (!sortedLists[character.type].hasOwnProperty(character.name)) {
      sortedLists[character.type][character.name] = [];
    }
    sortedLists[character.type][character.name].push({ character: character.charname, player: character.playername });
  }

  return (
    <React.Fragment>
      <Table stickyHeader={true}>
        <TableHead>
          <TableRow>
            <TableCell>
              List Type
            </TableCell>
            <TableCell>
              List Name
            </TableCell>
            <TableCell>
              Characters With
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {(listAnalytics.length > 0) && listHeaderMap(sortedLists, activeCharacters)}
        </TableBody>
      </Table>
    </React.Fragment>

  );
}

function listHeaderMap(lists, characterTotal) {
  const table = [];

  for (let i = 0; i < sortedListTypes.length; i++) {
    table.push(
      <React.Fragment key={i}>
        <TableRow style={{ backgroundColor: "CornflowerBlue" }} key={i}>
          <TableCell>
            {sortedListTypes[i]}
          </TableCell>
          <TableCell/>
          <TableCell/>
        </TableRow>
        {listNameMap(lists[sortedListTypes[i]], characterTotal)}
      </React.Fragment>
    );
  }
  return table;
}

function listNameMap(lists, characterTotal) {
  const rows = [];

  Object.keys(lists).sort().forEach(key => {
    rows.push(
      <TableRow key={key}>
        <TableCell/>
        <TableCell>
          <AnalyticsDialog
          field={key}
          characters={lists[key]}/>
        </TableCell>
        <TableCell>
          {((lists[key].length / characterTotal) * 100).toFixed(2)}%
        </TableCell>
      </TableRow>
    );
  });

  return rows;
}