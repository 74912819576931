import update from "immutability-helper";
import {
  getAllActivePlayers,
  getAllActivePlayersByName,
  makeNewPlayer,
  updateAPlayer
} from "../api/playerRequests";

const defaultState = {
  allActivePlayers: [],
  bannedPlayers: [],
  currentPlayer: [],
  currentPlayerCharacters: []
};

const REQUEST_PLAYERS = "REQUEST_PlAYERS";
const RECEIVE_PLAYERS = "RECEIVE_PlAYERS";
const FAILURE = "FAILURE";

function receiveAllPlayers(allActivePlayers) {
  return {
    type: RECEIVE_PLAYERS,
    allActivePlayers
  };
}

function failure(errors) {
  return {
    type: FAILURE,
    user: {
      errors: errors["json"]
        ? errors.json.errors
        : {
            error: {
              message: "I failed"
            }
          }
    }
  };
}

export function createNewPlayer() {
  return dispatch => {
    return makeNewPlayer().then(response =>
      dispatch(fetchAllActivePlayersLike("New"))
    );
  };
}

export function updatePlayer(player) {
  return dispatch => {
    if (player.id === 0) {
      delete player.id;
    }
    return updateAPlayer(player)
      .then(response => {
        dispatch(fetchAllActivePlayers());
      })
      .catch(errors => dispatch(failure(errors)));
  };
}

export function fetchAllActivePlayersLike(playername) {
  return dispatch => {
    dispatch({ type: REQUEST_PLAYERS });
    return getAllActivePlayersByName(playername)
      .then(response => dispatch(receiveAllPlayers(response)))
      .catch(errors => dispatch(failure(errors)));
  };
}

export function fetchAllActivePlayers() {
  return dispatch => {
    dispatch({ type: REQUEST_PLAYERS });
    return getAllActivePlayers()
      .then(response => dispatch(receiveAllPlayers(response)))
      .catch(errors => dispatch(failure(errors)));
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case REQUEST_PLAYERS:
      return { ...state, isFetchingPlayers: true };
    case RECEIVE_PLAYERS:
      return update(state, {
        isFetchingPlayers: { $set: false },
        allActivePlayers: { $set: action.allActivePlayers }
      });
    default:
      return state;
  }
}
