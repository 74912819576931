import React from "react";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import { Table } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableBody from "@material-ui/core/TableBody";

export function allegianceAnalytics(allegianceAnalytics, activeCharacters) {
  const allegianceTotal = allegianceAnalytics.length;
  const percentage = ((allegianceTotal / activeCharacters) * 100).toFixed(2);

  let uniqueAllegiances = {};

  for (const character of allegianceAnalytics) {
    if (!uniqueAllegiances.hasOwnProperty(character.skillname)) {
      uniqueAllegiances[character.skillname] = { character: [] };
    }
    uniqueAllegiances[character.skillname].character.push({ character: character.charname, player: character.playername });
  }
  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell colSpan={4}>
            Percentage with Allegiance: {percentage}%
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell colSpan={2}>
            Allegiance
          </TableCell>
          <TableCell>
            Character Name
          </TableCell>
          <TableCell>
            Player Name
          </TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {(allegianceAnalytics.length > 0) && allegianceHeaderMap(uniqueAllegiances, allegianceTotal)}
      </TableBody>
    </Table>
  );
}

function allegianceHeaderMap(allegiances, total) {
  const table = [];

  Object.keys(allegiances).sort().forEach(key => {
    table.push(
      <React.Fragment>
        <TableRow style={{backgroundColor: 'CornflowerBlue', color:'red'}}>
          <TableCell colSpan={2}>
            {key}
          </TableCell>
          <TableCell>
            Market share: {((allegiances[key].character.length / total) * 100).toFixed(2)}%
          </TableCell>
          <TableCell/>
        </TableRow>
        {allegianceCharacterMap(allegiances[key].character)}
      </React.Fragment>
    );
  });

  return table;
}

function allegianceCharacterMap(characters) {
  return characters.sort((a,b) => a.character.localeCompare(b.character)).map((char, index) => {
    return (
      <TableRow key={index}>
        <TableCell colSpan={2}/>
        <TableCell>
          {char.character}
        </TableCell>
        <TableCell>
          {char.player}
        </TableCell>
      </TableRow>
    );
  });
}