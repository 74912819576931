import React from "react";
import { Route, Link } from "react-router-dom";

export default class AdminHeader extends React.Component {
  render() {
    return (
      <div>
        <Link to="/players">
          <button>{"Players"}</button>
        </Link>

        <Link to="/games">
          <button>{"Games"}</button>
        </Link>

        <Link to="/characterOptions">
          <button>{"Character Option Input"}</button>
        </Link>

        <Link to="/analytics">
          <button>{"Character Analytics"}</button>
        </Link>

        <Route
          exact
          path="/characterOptions"
          render={() => (
            <div>
              <Link to="/characterOptions/vices">
                <button>{"Vice/Virtue manager"}</button>
              </Link>

              <Link to="/characterOptions/lists">
                <button>{"Lists manager"}</button>
              </Link>
              <Link to="/characterOptions/skills">
                <button>{"Skills Manager"}</button>
              </Link>
            </div>
          )}
        />
      </div>
    );
  }
}
