import fetchJSON from "./fetchJSON";
import { queryCheck } from "../actions/validation";

export function getCurrentCharacters(playerID) {
  const options = {
    method: "GET"
  };
  return fetchJSON("/characters/" + playerID, options).then(results => {
    return queryCheck(results);
  });
}

export function getSingleCharacter(charid) {
  const options = {
    method: "GET"
  };
  return fetchJSON("/characters/single/" + charid, options).then(results => {
    return queryCheck(results);
  });
}

export function makeNewCharacter(playerID) {
  const options = {
    method: "GET"
  };
  return fetchJSON("/characters/makenewcharacter/" + playerID, options).then(
    results => {
      return queryCheck(results);
    }
  );
}

export function updateACharacter(character) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(character)
  };
  return fetchJSON("/characters/", options).then(results => {
    return queryCheck(results);
  });
}

export function delCharacter(id) {
  const options = {
    method: "DELETE"
  };
  return fetchJSON("/characters/" + id, options).then(results => {
    return queryCheck(results);
  });
}
