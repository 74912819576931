import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import FormGroup from "@material-ui/core/FormGroup/FormGroup";
import {
  fetchCurrentCharacters,
  deleteCharacter,
  createNewCharacter
} from "../../reducers/characterReducer";
import Select from "@material-ui/core/Select";
import MenuItem from "@material-ui/core/MenuItem";
import CharacterDialog from "../character/characterDialog";
import { updatePlayer } from "../../reducers/playerReducer";
import { validateSubmission } from "../../actions/validation";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import { getSingleCharacter } from "../../api/characterRequests";
import { postPrint } from "../../api/printRequests";
import { handlePrinting } from "../../actions/commonActions";

class PlayerDialog extends Component {
  //this class will functionally work as "Characters.js" would
  constructor(props) {
    super(props);

    this.state = {
      player: {
        id: props.player.id,
        firstname: props.player.firstname,
        lastname: props.player.lastname,
        startdate: props.player.startdate,
        phonenumber: props.player.phonenumber,
        email: props.player.email,
        emerwaiversigned: props.player.emerwaiversigned,
        emerwaiverdate: props.player.emerwaiverdate,
        photowaiverdate: props.player.photowaiverdate,
        photowaiversigned: props.player.photowaiversigned,
        emername: props.player.emername,
        emerphone: props.player.emerphone,
        emerrelation: props.player.emerrelation,
        discipline: props.player.discipline,
        ban: props.player.ban,
        reference: props.player.reference,
        playernotes: props.player.playernotes,
        adminnotes: props.player.adminnotes,
        specrestrict: props.player.specrestrict,
        healthneeds: props.player.healthneeds,
        staff: props.player.staff,
        playernumber: props.player.playernumber,
        position: props.player.position,
        totalsp: props.player.totalsp
      },
      openCharacter: false
    };

    this.props.fetchCurrentCharacters(props.player.id);
  }

  _onSubmit() {
    //the submit from the playerdialog
    const reqProps = ["firstname", "lastname", "playernumber"];
    if (
      validateSubmission(reqProps, this.state.player) &&
      Boolean(this.state.player.firstname) &&
      Boolean(this.state.player.lastname) &&
      Boolean(this.state.player.playernumber)
    ) {
      this.props.upsertPlayer(this.state.player);
      this.props.close();
    } else {
      alert("Some required fields are missing");
    }
  }

  handlePlayerChange(name, value) {
    //dialog gets this function, which updates the state and values
    let editedPlayer = this.state.player;
    editedPlayer[name] = value;
    this.setState({ player: editedPlayer });
  }

  newCharacterBtn() {
    if (this.state.player.id === 0) {
      return <TableRow></TableRow>;
    } else {
      return (
        <TableRow>
          <TableCell>
            <Button
              onClick={this.newCharacterMaker.bind(this)}
              variant={"contained"}
              value={"new"}
            >
              New Character
            </Button>
          </TableCell>
        </TableRow>
      );
    }
  }

  newCharacterMaker() {
    this.props.makeNewCharacter(this.state.player.id);
  }

  characters() {
    return this.props.currentCharacters.map((character, row) => {
      return (
        <TableRow key={row}>
          <TableCell>{character.charname}</TableCell>
          <TableCell>
            <Select
              value={""}
              onChange={this.handleCharacterSelect.bind(this, row)}
            >
              <MenuItem value="edit">Edit</MenuItem>
              <MenuItem value="delete">Delete</MenuItem>
              <MenuItem value="print">Print Sheet</MenuItem>
            </Select>
          </TableCell>
        </TableRow>
      );
    });
  }

  async editCharacter(charid) {
    let reply = await getSingleCharacter(charid);
    this.setState({ blankCharacter: reply, openCharacter: true });
  }

  async handleCharacterSelect(selectedRow, event) {
    switch (event.target.value) {
      case "edit":
        this.editCharacter(this.props.currentCharacters[selectedRow].id);
        break;
      case "delete":
        if (window.confirm("Delete this character?") === true) {
          this.props.deleteCharacter(
            this.props.currentCharacters[selectedRow].id,
            this.state.player.id
          );
        }
        break;
      case "print":
        handlePrinting([this.props.currentCharacters[selectedRow].id]);
        break;
      default:
        return alert("I should not happen");
    }
  }

  player() {
    return (
      <div>
        <Table className="PlayerEditTable">
          <TableBody>
            <TableRow>
              <TableCell>
                <TextField
                  required
                  value={this.state.player.playernumber}
                  label={"Player Number"}
                  type="number"
                  onChange={e =>
                    this.handlePlayerChange("playernumber", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={this.state.player.reference}
                  label={"Referred by"}
                  type="number"
                  onChange={e =>
                    this.handlePlayerChange("reference", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  required
                  type="date"
                  value={this.state.player.startdate}
                  label={"Start Date"}
                  onChange={e =>
                    this.handlePlayerChange("startdate", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(this.state.player.ban)}
                        onChange={e =>
                          this.handlePlayerChange("ban", e.target.checked)
                        }
                        value={"ban"}
                      />
                    }
                    label={"Player Banned"}
                  />
                </FormGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  required
                  value={this.state.player.firstname}
                  label={"First Name"}
                  onChange={e =>
                    this.handlePlayerChange("firstname", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  required
                  value={this.state.player.lastname}
                  label={"Last Name"}
                  onChange={e =>
                    this.handlePlayerChange("lastname", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  disabled
                  value={this.state.player.totalsp}
                  label={"Total SP"}
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(this.state.player.staff)}
                        onChange={e =>
                          this.handlePlayerChange("staff", e.target.checked)
                        }
                        value={"staff"}
                      />
                    }
                    label={"Staff Member"}
                  />
                </FormGroup>
              </TableCell>
              <TableCell>
                <TextField
                  value={this.state.player.position}
                  label={"Staff Position"}
                  onChange={e =>
                    this.handlePlayerChange("position", e.target.value)
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  value={this.state.player.phonenumber}
                  label={"Phone Number"}
                  onChange={e =>
                    this.handlePlayerChange("phonenumber", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={this.state.player.email}
                  label={"E-Mail"}
                  onChange={e =>
                    this.handlePlayerChange("email", e.target.value)
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  value={this.state.player.emername}
                  label={"Emergency Contact"}
                  onChange={e =>
                    this.handlePlayerChange("emername", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={this.state.player.emerrelation}
                  label={"Contact Relationship"}
                  onChange={e =>
                    this.handlePlayerChange("emerrelation", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={this.state.player.emerphone}
                  label={"Emergency Phone"}
                  onChange={e =>
                    this.handlePlayerChange("emerphone", e.target.value)
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  type="date"
                  value={this.state.player.emerwaiverdate}
                  label={"Emergency Waiver Date"}
                  onChange={e =>
                    this.handlePlayerChange("emerwaiverdate", e.target.value)
                  }
                />
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(this.state.player.emerwaiversigned)}
                        onChange={e =>
                          this.handlePlayerChange(
                            "emerwaiversigned",
                            e.target.checked
                          )
                        }
                        value={"emerwaiversigned"}
                        color={"primary"}
                      />
                    }
                    label={"Emergency Waiver Signed"}
                  />
                </FormGroup>
              </TableCell>
              <TableCell>
                <TextField
                  type="date"
                  value={this.state.player.photowaiverdate}
                  label={"Photo Waiver Date"}
                  onChange={e =>
                    this.handlePlayerChange("photowaiverdate", e.target.value)
                  }
                />
                <FormGroup row>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={Boolean(this.state.player.photowaiversigned)}
                        onChange={e =>
                          this.handlePlayerChange(
                            "photowaiversigned",
                            e.target.checked
                          )
                        }
                        value={"photowaiversigned"}
                        color={"primary"}
                      />
                    }
                    label={"Photo Waiver Signed"}
                  />
                </FormGroup>
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  value={this.state.player.discipline}
                  label={"Disciplinary Actions"}
                  multiline
                  rows="4"
                  onChange={e =>
                    this.handlePlayerChange("discipline", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={this.state.player.playernotes}
                  label={"Player Notes"}
                  multiline
                  rows="4"
                  onChange={e =>
                    this.handlePlayerChange("playernotes", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={this.state.player.adminnotes}
                  label={"Administration Notes"}
                  multiline
                  rows="4"
                  onChange={e =>
                    this.handlePlayerChange("adminnotes", e.target.value)
                  }
                />
              </TableCell>
            </TableRow>
            <TableRow>
              <TableCell>
                <TextField
                  value={this.state.player.specrestrict}
                  label={"Special Restrictions"}
                  multiline
                  rows="4"
                  onChange={e =>
                    this.handlePlayerChange("specrestrict", e.target.value)
                  }
                />
              </TableCell>
              <TableCell>
                <TextField
                  value={this.state.player.healthneeds}
                  label={"Health Needs"}
                  multiline
                  rows="4"
                  onChange={e =>
                    this.handlePlayerChange("healthneeds", e.target.value)
                  }
                />
              </TableCell>
            </TableRow>
            {this.newCharacterBtn()}
            {this.characters()}
            {this.state.openCharacter && <CharacterDialog
              close={() => {
                this.setState({ openCharacter: false });
              }}
              open={this.state.openCharacter}
              character={this.state.blankCharacter}
              playerName={
                this.state.player.firstname + this.state.player.lastname
              }
            />}
          </TableBody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} fullScreen>
          {this.player()}
          <DialogActions>
            <Button onClick={this.props.close} color="secondary">
              Cancel
            </Button>
            <Button onClick={this._onSubmit.bind(this)} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    currentCharacters: state.character.currentPlayerCharacters,
    allLists: state.lists.lists
  };
}

function mapDispatchToProps(dispatch) {
  return {
    deleteCharacter: function(charId, playerId) {
      dispatch(deleteCharacter(charId, playerId));
    },
    fetchCurrentCharacters: function(playerid) {
      dispatch(fetchCurrentCharacters(playerid));
    },
    makeNewCharacter: function(playerid) {
      dispatch(createNewCharacter(playerid));
    },
    upsertPlayer: function(playerid, player) {
      dispatch(updatePlayer(playerid, player));
    }
  };
}

PlayerDialog.propTypes = {
  player: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PlayerDialog);
