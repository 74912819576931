import update from "immutability-helper";
import {
  getAllGames,
  updateAGame,
  updateAGameAttendance,
  delGame,
  getGameSummary,
  removeGameAttendance
} from "../api/gameRequests";

const REQUEST_GAMES = "REQUEST_GAMES";
const RECEIVE_GAMES = "RECEIVE_GAME";
const FAILURE = "FAILURE";
const SET_CURRENT_GAMES = "SET_CURRENT_GAMES";
const GAME_SUMMARY = "GAME_SUMMARY";

function failure(errors) {
  return {
    type: FAILURE,
    user: {
      errors: errors["json"]
        ? errors.json.errors
        : {
            error: {
              message: "I failed"
            }
          }
    }
  };
}

const defaultState = {
  currentGame: {},
  allGames: [],
  gameSummary: []
};

export function updateGameAttendance(body) {
  return dispatch => {
    return updateAGameAttendance(body).then(response => {
      dispatch(fetchGameSummary(body.gameid));
    });
  };
}

export function deleteAGameAttendance(gameid, playerid) {
  return dispatch => {
    return removeGameAttendance(gameid, playerid).then(response =>
      dispatch(fetchGameSummary(gameid))
    );
  };
}

function receiveAllGames(allGames) {
  return {
    type: RECEIVE_GAMES,
    allGames
  };
}

function receiveGameSummary(summary) {
  return {
    type: GAME_SUMMARY,
    summary
  };
}

export function fetchGameSummary(gameid) {
  return dispatch => {
    return getGameSummary(gameid)
      .then(response => dispatch(receiveGameSummary(response)))
      .catch(errors => dispatch(failure(errors)));
  };
}

export function fetchAllGames() {
  return dispatch => {
    dispatch({ type: REQUEST_GAMES });
    return getAllGames()
      .then(response => dispatch(receiveAllGames(response)))
      .catch(errors => dispatch(failure(errors)));
  };
}

export function updateGame(details) {
  return dispatch => {
    return updateAGame(details).then(response => dispatch(fetchAllGames()));
  };
}

export function deleteGame(id) {
  return dispatch => {
    return delGame(id).then(response => dispatch(fetchAllGames()));
  };
}

export default function reducer(state = defaultState, action) {
  switch (action.type) {
    case RECEIVE_GAMES:
      return update(state, {
        allGames: { $set: action.allGames }
      });
    case SET_CURRENT_GAMES:
      return update(state, {
        allGames: { $set: action.games }
      });
    case GAME_SUMMARY:
      return update(state, {
        gameSummary: { $set: action.summary }
      });
    default:
      return state;
  }
}
