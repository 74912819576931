import phoneUtil from "google-libphonenumber";

function is_email(email) {
  var emailReg = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
  return emailReg.test(email);
}

export function isPhoneNumber(phoneCheck) {
  try {
    phoneUtil.parse(phoneCheck, "US");
    return true;
  } catch (error) {
    return false;
  }
}


export function queryCheck(result) {
  if (result.hasOwnProperty("success")) console.log("I have success");
  if (result.success === false) console.log("I have fail");

  if (result.hasOwnProperty("success") && result.success === false)
    try {
      if (result.hasOwnProperty("message"))
        throw Error(result.message);
      else
        throw Error("failure to process.  Verify required fields");
    } catch (e) {
      alert(e);
    }
  else return result;
}


export function validateSubmission(reqProps, props) {
  return reqProps.every(i => props.hasOwnProperty(i));
}
