import fetchFile from "./fetchFile";

export function postPrint(charIds) {
  const options = {
    method: "POST",
    headers: {
      Accept: "*/*",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(charIds)
  };
  return fetchFile("/print/", options).then(results => {
    return results;
  });
}