import Main from "./pages/main";
import Players from "./components/players/playersList";
import Vices from "./components/vices/vicesList";
import Games from "./components/games/gamesList";
import Skills from "./components/skills/skillsList";
import Analytics from "./components/analytics/analytics";
import AdminHeader from "./pages/adminHeader";
import CharacterOptionsPage from "./pages/options/characterOptions";
import Lists from "./components/lists/listsList";
import React from "react";
import {Route, Switch} from "react-router-dom";

export default () => {
    return (
        <div>
            <AdminHeader/>
            <Switch>
                <Route exact path="/" component={Main}/>
                <Route path="/about" component={Main}/>
                <Route path="/players" component={Players}/>
                <Route path="/games" component={Games}/>
                <Route path="/characterOptions/lists" component={Lists}/>
                <Route path="/characterOptions/vices" component={Vices}/>
                <Route path="/characterOptions/skills" component={Skills}/>
                <Route path="/characterOptions" component={CharacterOptionsPage}/>
                <Route path="/analytics" component={Analytics}/>
            </Switch>
        </div>
    );
};
