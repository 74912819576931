import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { menuNumbers } from "../../actions/commonActions";
import MenuItem from "@material-ui/core/MenuItem/index";
import TextField from "@material-ui/core/TextField";
import Select from "@material-ui/core/Select/Select";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Checkbox from "@material-ui/core/Checkbox";
import { fetchVices } from "../../reducers/vicevirtuesReducer";
import { updateCharacter } from "../../reducers/characterReducer";
import { fetchAccessLists, fetchListsByType } from "../../reducers/listsReducer";
import { getAvailableSkills } from "../../reducers/skillsReducer";
import Button from "@material-ui/core/Button";
import ClearIcon from '@material-ui/icons/Clear';
import * as _ from "lodash";
import Paper from "@material-ui/core/Paper";
import Grid from "@material-ui/core/Grid";
import { validateSubmission } from "../../actions/validation";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import CharacterSkillSelectDialog from "./characterSkillSelectDialog";
import Autocomplete from "../util/autoComplete";

const singleLists = ["culture", "birthright", "monstrosity"];

class CharacterDialog extends Component {
  constructor(props) {
    super(props);

    let char = JSON.parse(JSON.stringify(props.character));

    char = this.calcSpentXP(char);
    char = this.calcTotalFocus(char);
    char = this.calcTotalHealth(char);
    char = this.calcFocusRecovery(char);
    char = this.calcPowerPool(char);

    this.state = {
      character: char,
      openSkillDialog: false
    };

    this.props.fetchVices();
    this.props.fetchLists();
    this.props.fetchListsByType();
    this.props.fetchAvailableSkills(this.state.character.lists);
  }

  handleChange(name, value) {
    let editedChar = this.state.character;
    editedChar[name] = value;
    this.setState({ character: editedChar });
  }

  getListMenuItems(listType) {
    return this.props.allLists[listType].map((item, index) => {
      if(item.name !== 'universal') {
        return (
          <MenuItem value={item.id} key={index + 1}>
            {item.name}
          </MenuItem>
        );
      }
    });
  }

  listSelection() {
    return (
      <Table className="listTable">
        <TableBody>
          <TableRow>
            <TableCell>
              <InputLabel>Culture</InputLabel>
              <Select
                value={this.state.character.lists.culture.id}
                onChange={(event, value) =>
                  this.handleListChange("culture", event.target.value, value)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("culture")}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Birthright</InputLabel>
              <Select
                value={this.state.character.lists.birthright.id}
                onChange={(event, value) =>
                  this.handleListChange("birthright", event.target.value, value)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("birthright")}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Primary List 1</InputLabel>
              <Select
                value={this.state.character.lists.primary[0].id}
                onChange={e =>
                  this.handleListChange("primary", e.target.value, 0)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("list")}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Primary List 2</InputLabel>
              <Select
                value={this.state.character.lists.primary[1].id}
                onChange={e =>
                  this.handleListChange("primary", e.target.value, 1)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("list")}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Primary List 3</InputLabel>
              <Select
                value={this.state.character.lists.primary[2].id}
                onChange={e =>
                  this.handleListChange("primary", e.target.value, 2)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("list")}
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <InputLabel>Secondary List 1</InputLabel>
              <Select
                value={this.state.character.lists.secondary[0].id}
                onChange={e =>
                  this.handleListChange("secondary", e.target.value, 0)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("list")}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Secondary List 2</InputLabel>
              <Select
                value={this.state.character.lists.secondary[1].id}
                onChange={e =>
                  this.handleListChange("secondary", e.target.value, 1)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("list")}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Secondary List 3</InputLabel>
              <Select
                value={this.state.character.lists.secondary[2].id}
                onChange={e =>
                  this.handleListChange("secondary", e.target.value, 2)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("list")}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Secondary List 4</InputLabel>
              <Select
                value={this.state.character.lists.secondary[3].id}
                onChange={e =>
                  this.handleListChange("secondary", e.target.value, 3)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("list")}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Secondary List 5</InputLabel>
              <Select
                value={this.state.character.lists.secondary[4].id}
                onChange={e =>
                  this.handleListChange("secondary", e.target.value, 4)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("list")}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Secondary List 6</InputLabel>
              <Select
                value={this.state.character.lists.secondary[5].id}
                onChange={e =>
                  this.handleListChange("secondary", e.target.value, 5)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("list")}
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <InputLabel>Archetype 1</InputLabel>
              <Select
                value={this.state.character.lists.archetype[0].id}
                onChange={e =>
                  this.handleListChange("archetype", e.target.value, 0)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("archetype")}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Archetype 2</InputLabel>
              <Select
                value={this.state.character.lists.archetype[1].id}
                onChange={e =>
                  this.handleListChange("archetype", e.target.value, 1)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("archetype")}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Monstrosity</InputLabel>
              <Select
                value={this.state.character.lists.monstrosity.id}
                onChange={e =>
                  this.handleListChange("monstrosity", e.target.value)
                }
              >
                <MenuItem value={false}>None</MenuItem>
                {this.getListMenuItems("monstrosity")}
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              Skill Search
              <Autocomplete
              suggestions={this.getSkillSuggestions()}
              provideSkill={(index) => this.handleSkillChange(index)}
              />
            </TableCell>
            <TableCell>
              <Button
                onClick={() => this.setState({ openSkillDialog: true })}
                color="primary"
              >
                Skills Menu
              </Button>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  getSkillSuggestions(){
    return this.props.availableSkills.map((item) => {
      return (
        item.skillcost + " " + item.skillname + " " +  item.listname
      );
      }
    );
  }

  getSkillListMenuItems() {
    return this.props.availableSkills.map((item, index) => {
      return (
        <MenuItem value={item} key={index}>
          {item.xpcost} {item.skillname} {item.listname}
        </MenuItem>
      );
    });
  }

  handleSkillChange(index) {
    let value = this.props.availableSkills[index];
    let editedChar = this.state.character;
    let editedSkill = editedChar.skills;

    if (!editedSkill.map(a => a.skillid).includes(value.skillid)) {
      editedSkill.push(value);

      editedChar.skills = editedSkill;
      this.setState({ character: editedChar }, () => this.calcTotals());
    }
  }

  handleListChange(name, value, index) {
    let editedChar = this.state.character;
    let editedList = editedChar.lists;

    if (singleLists.includes(name)) {
      if (value) {
        editedList[name].id = value;
      } else {
        editedList[name] = {};
      }
    } else {
      if (value) {
        editedList[name][index].id = value;
      } else {
        editedList[name][index] = {};
      }
    }
    editedChar.lists = editedList;
    this.setState({ character: editedChar }, () => {
      this.props.fetchAvailableSkills(editedList);
      this.calcTotals();
    });
  }

  character() {
    return (
      <Table className="characterTable">
        <TableBody>
          <TableRow>
            <TableCell>
              <TextField
                disabed={"true"}
                value={this.props.playerName}
                label={"Player"}
              />
            </TableCell>
            <TableCell>
              <TextField
                required
                value={this.state.character.charname}
                label={"Character Name"}
                onChange={e => this.handleChange("charname", e.target.value)}
              />
            </TableCell>
            <TableCell>
              <TextField
                disabed={"true"}
                value={this.state.character.totalxp}
                label={"Total XP"}
              />
            </TableCell>
            <TableCell>
              <TextField
                disabed={"true"}
                value={this.state.character.spentxp}
                label={"Spent XP"}
              />
            </TableCell>

          </TableRow>
          <TableRow>
            <TableCell>
              <TextField
                value={this.state.character.startingxp}
                label={"Starting XP"}
                type="number"
                onChange={e => this.handleChange("startingxp", e.target.value)}
              >
                {menuNumbers(10)}
              </TextField>
            </TableCell>
            <TableCell>
              <TextField
                disabed={"true"}
                value={this.state.character.totalhealth}
                label={"Max Health"}
              />
            </TableCell>
            <TableCell>
              <TextField
                disabed={"true"}
                value={this.state.character.totalfocus}
                label={"Max Focus"}
              />
            </TableCell>
            <TableCell>
              <TextField
                disabed={"true"}
                value={this.state.character.focusrecovery}
                label={"Focus Recovery"}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <InputLabel>Favored Vice/Virtue</InputLabel>
              <Select
                value={this.state.character.favoredvicevirtue}
                onChange={e =>
                  this.handleChange("favoredvicevirtue", e.target.value)
                }
              >
                {this.viceVirtueMenu()}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Forsworn Vice/Virtue</InputLabel>
              <Select
                value={this.state.character.forswornvicevirtue}
                onChange={e =>
                  this.handleChange("forswornvicevirtue", e.target.value)
                }
              >
                {this.viceVirtueMenu()}
              </Select>
            </TableCell>
            <TableCell>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(this.state.character.retired)}
                      onChange={e =>
                        this.handleChange("retired", e.target.checked)
                      }
                      value={"retired"}
                    />
                  }
                  label={"Retired"}
                />
              </FormGroup>
            </TableCell>
            <TableCell>
              <FormGroup row>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={Boolean(this.state.character.dead)}
                      onChange={e =>
                        this.handleChange("dead", e.target.checked)
                      }
                      value={"dead"}
                    />
                  }
                  label={"Dead"}
                />
              </FormGroup>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField
                disabed={"true"}
                value={this.state.character.powerpool}
                label={"Power Pool"}
              />
            </TableCell>
            <TableCell>
              <InputLabel>Debt</InputLabel>
              <Select
                value={this.state.character.debt}
                onChange={e => this.handleChange("debt", e.target.value)}
              >
                {menuNumbers(20)}
              <MenuItem value={666} key={666}>{666}</MenuItem>
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Madness</InputLabel>
              <Select
                value={this.state.character.madness}
                onChange={e => this.handleChange("madness", e.target.value)}
              >
                {menuNumbers(6)}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Corruption</InputLabel>
              <Select
                value={this.state.character.corruption}
                onChange={e => this.handleChange("corruption", e.target.value)}
              >
                {menuNumbers(8)}
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField
                value={this.state.character.spouse}
                label={"Spouse's Name/Player Number"}
                onChange={e => this.handleChange("spouse", e.target.value)}
              />
            </TableCell>
            <TableCell>
              <TextField
                value={this.state.character.magictraits}
                label={"Afflictions and Madness"}
                multiline
                onChange={e => this.handleChange("magictraits", e.target.value)}
                rows="4"
              />
            </TableCell>
            <TableCell>
              <TextField
                value={this.state.character.playernotes}
                label={"Player Notes"}
                multiline
                onChange={e => this.handleChange("playernotes", e.target.value)}
                rows="4"
              />
            </TableCell>
            <TableCell>
              <TextField
                value={this.state.character.adminnotes}
                label={"Character Traits"}
                multiline
                onChange={e => this.handleChange("adminnotes", e.target.value)}
                rows="4"
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  skillsGrid() {
    return (
      <Grid container spacing={3}>
        {this.skillsPaperForGrid()}
      </Grid>
    );
  }

  skillsPaperForGrid() {
    return this.state.character.skills.map((item, key) => {
      return (
        <Grid item key={key}>
          <Paper>
            {item.skillcost} - {item.skillname}
            <Button onClick={() => this.removeSkill(key)}>
              <ClearIcon color="primary"/>
            </Button>
          </Paper>
        </Grid>
      );
    });
  }

  removeSkill(index) {
    let editedChar = this.state.character;
    const removableSkill = this.state.character.skills[index];

    editedChar.skills = _.remove(editedChar.skills, function(ele) {
      return ele !== removableSkill;
    });
    this.setState({ character: editedChar }, () => this.calcTotals());
  }

  viceVirtueMenu() {
    return this.props.allVices.map((vice, key) => {
      return (
        <MenuItem key={key} value={vice.id}>
          {vice.name}
        </MenuItem>
      );
    });
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} fullScreen>
          {this.character()}
          {this.listSelection()}
          {this.skillsGrid()}
          <DialogActions>
            <Button onClick={this.props.close} color="secondary">
              Cancel
            </Button>
            <Button onClick={this._onSubmit.bind(this)} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
        <Dialog open={this.state.openSkillDialog} fullScreen>
          <CharacterSkillSelectDialog
            addSkill={value => this.handleSkillChange(value)}
            removeSkill={index => this.removeSkill(index)}
            currentSkills={this.state.character.skills}
            availableSkills={this.props.availableSkills}
          />
          <DialogActions>
            <Button
              onClick={() => this.setState({ openSkillDialog: false })}
              color="primary"
            >
              Accept
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }

  _onSubmit() {
    const reqProps = ["id", "charname"];
    if (
      validateSubmission(reqProps, this.state.character) &&
      Boolean(this.state.character.charname)
    ) {
      this.props.upsertCharacter(this.createCharacterSubmission());
      this.props.close();
    } else {
      alert("Some required fields are missing");
    }
  }

  calcTotals() {
    let char = this.state.character;

    char = this.calcSpentXP(char);
    char = this.calcTotalFocus(char);
    char = this.calcTotalHealth(char);
    char = this.calcFocusRecovery(char);
    char = this.calcPowerPool(char);
    this.setState({ character: char });
  }

  calcPowerPool(char) {
    let totalPool = 0;

    char.skills.forEach(function(ele) {
      totalPool += ele.powerpool;
    });

    char.powerpool = totalPool;

    return char;
  }

  calcFocusRecovery(char) {
    let totalRecovery = 5;

    char.skills.forEach(function(ele) {
      totalRecovery += ele.focusrecovery;
    });

    char.focusrecovery = totalRecovery;

    return char;
  }


  calcTotalHealth(char) {
    let totalHealth = 7;

    char.skills.forEach(function(ele) {
      totalHealth += ele.bonushp;
    });

    char.totalhealth = totalHealth;

    return char;
  }

  calcTotalFocus(char) {
    let totalFocus = 10;

    char.skills.forEach(function(ele) {
      totalFocus += ele.bonusfp;
    });

    char.totalfocus = totalFocus;

    return char;
  }

  calcSpentXP(char) {
    let usedXP = 0;

    usedXP += char.lists.primary.filter(a => a.hasOwnProperty("id")).length * 5;
    usedXP +=
      char.lists.secondary.filter(a => a.hasOwnProperty("id")).length * 10;
    usedXP +=
      char.lists.archetype.filter(a => a.hasOwnProperty("id")).length * 15;

    char.skills.forEach(function(ele) {
      usedXP += ele.skillcost;
    });

    char.spentxp = usedXP;

    return char;
  }

  //cost 0 gets culture and birthright
  //cost5 gets primary lists
  //cost10 gets secondary lists
  //cost15 gets archetypes and monstrosities
  //remove lists gets ids from all lists that need to be removed
  //this.state.character is the NEW version, this.props.character is the old version
  createCharacterSubmission() {
    let updatedCharacter = this.state.character;
    let oldCharacter = this.props.character;
    let removeLists = [];
    let cost0 = [];
    let cost5 = [];
    let cost10 = [];
    let cost15 = [];

    let oldSkills = oldCharacter.skills.map(a => a.skillid);
    let newSkills = updatedCharacter.skills.map(a => a.skillid);

    const addSkills = newSkills.filter(x => !oldSkills.includes(x));
    const removeSkills = oldSkills.filter(x => !newSkills.includes(x));

    ["primary", "secondary", "archetype"].forEach(function(ele) {
      const newLists = updatedCharacter.lists[ele].map(a => a.id);
      const oldLists = oldCharacter.lists[ele].map(a => a.id);

      const listsToRemove = oldLists.filter(x => !newLists.includes(x));
      if (listsToRemove) removeLists = removeLists.concat(listsToRemove);

      if (ele === "primary") {
        const push5 = newLists.filter(x => !oldLists.includes(x));
        if (push5) cost5 = cost5.concat(push5);
      } else if (ele === "secondary") {
        const push10 = newLists.filter(x => !oldLists.includes(x));
        if (push10) cost10 = cost10.concat(push10);
      } else if (ele === "archetype") {
        const push15 = newLists.filter(x => !oldLists.includes(x));
        if (push15) cost15 = push15.concat(push15);
      }
    });

    ["culture", "birthright", "monstrosity"].forEach(function(ele) {
      if (
        oldCharacter.lists[ele].hasOwnProperty("id") &&
        updatedCharacter.lists[ele].hasOwnProperty("id") &&
        oldCharacter.lists[ele].id !== updatedCharacter.lists[ele].id
      ) {
        removeLists.push(oldCharacter.lists[ele].id);
        if (
          (ele === "culture" || ele === "birthright" || ele === 'monstrosity') &&
          updatedCharacter.lists[ele].hasOwnProperty("id")
        ) {
          cost0.push(updatedCharacter.lists[ele].id);
        }
      } else if (
        !oldCharacter.lists[ele].hasOwnProperty("id") &&
        updatedCharacter.lists[ele].hasOwnProperty("id")
      ) {
        if (
          (ele === "culture" || ele === "birthright" || ele === "monstrosity") &&
          updatedCharacter.lists[ele].hasOwnProperty("id")
        ) {
          cost0.push(updatedCharacter.lists[ele].id);
        }
      } else if (
        oldCharacter.lists[ele].hasOwnProperty("id") &&
        !updatedCharacter.lists[ele].hasOwnProperty("id")
      ) {
        removeLists.push(oldCharacter.lists[ele].id);
      }
    });

    updatedCharacter.removeLists = removeLists;
    updatedCharacter.removeSkills = removeSkills;
    updatedCharacter.cost0 = cost0;
    updatedCharacter.cost5 = cost5;
    updatedCharacter.cost10 = cost10;
    updatedCharacter.cost15 = cost15;
    updatedCharacter.addSkills = addSkills;

    return updatedCharacter;
  }
}

CharacterDialog.propTypes = {
  character: PropTypes.object.isRequired,
  playerName: PropTypes.string.isRequired
};

function mapStateToProps(state) {
  return {
    allVices: state.vices.vices,
    allLists: state.lists.listType,
    availableSkills: state.skills.availableSkills
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchVices: function() {
      dispatch(fetchVices());
    },
    fetchLists: function() {
      dispatch(fetchAccessLists());
    },
    fetchListsByType: function() {
      dispatch(fetchListsByType());
    },
    upsertCharacter: function(character) {
      dispatch(updateCharacter(character));
    },
    fetchAvailableSkills: function(lists) {
      dispatch(getAvailableSkills(lists));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CharacterDialog);
