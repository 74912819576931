import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { menuNumbers } from "../../actions/commonActions";
import {
  fetchGameSummary,
  updateGameAttendance
} from "../../reducers/gamesReducer";
import Checkbox from "@material-ui/core/Checkbox/index";
import MenuItem from "@material-ui/core/MenuItem/index";
import TextField from "@material-ui/core/TextField/index";
import Select from "@material-ui/core/Select/Select";
import InputLabel from "@material-ui/core/InputLabel/InputLabel";
import { fetchAllActivePlayers } from "../../reducers/playerReducer";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import FormGroup from "@material-ui/core/FormGroup";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import Button from "@material-ui/core/Button";
import { getGameAttendance } from "../../api/gameRequests";

class GameAttendanceDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      gameAttend: props.attendance,
      height: "5px",
      width: "3px",
      selectable: false
    };

    this.props.fetchAllActivePlayers();
  }

  state = {
    height: "5px",
    width: "3px"
  };

  _onSubmit() {
    this.props.updateAGameAttendance(this.state.gameAttend);
    this.props.fetchGameSummary(this.props.gameid);
    this.props.close();
  }

  _handlePlayerChange(name, value) {
    let editPlayer = this.state.gameAttend;
    editPlayer[name] = value;
    this.setState({ gameAttend: editPlayer });
  }

  _handleCharacterChange(name, value, index) {
    let editChar = this.state.gameAttend;
    editChar.characters[index][name] = value;
    this.setState({ gameAttend: editChar });
  }

  async switchAttendingPlayer(playerid) {
    let reply = await getGameAttendance(this.props.gameid, playerid);
    this.setState({ gameAttend: reply });
  }

  playerAttend() {
    return (
      <Table className="playersTable" width={"6px"}>
        <TableHead colSpan={6}>
          <TableRow>
            <TableCell>
              <InputLabel>Player:</InputLabel>
              <Select
                value={this.state.gameAttend.playerid}
                onChange={event =>
                  this.switchAttendingPlayer(event.target.value)
                }
              >
                {this.playersMenuItems()}
              </Select>
            </TableCell>
            <TableCell>Game: {this.state.gameAttend.gamenumber}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            <TableCell>
              <InputLabel>Staff XP</InputLabel>
              <Select
                value={this.state.gameAttend.staffxp}
                onChange={event =>
                  this._handlePlayerChange("staffxp", event.target.value)
                }
              >
                {menuNumbers(3)}
              </Select>
            </TableCell>
            <TableCell>
              <TextField
                label={"Staff Position"}
                value={this.state.gameAttend.position}
                disabled={true}
              />
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <TextField
                label={"SP Gained"}
                value={this.state.gameAttend.gainsp}
                onChange={event =>
                  this._handlePlayerChange("gainsp", event.target.value)
                }
              />
            </TableCell>
            <TableCell>
              <TextField
                label={"SP Spent"}
                value={this.state.gameAttend.spendsp}
                onChange={event =>
                  this._handlePlayerChange("spendsp", event.target.value)
                }
              />
            </TableCell>
            <TableCell>
              <TextField
                label={"SP Notes"}
                value={this.state.gameAttend.spnotes}
                multiline
                rows={"2"}
                onChange={event =>
                  this._handlePlayerChange("spnotes", event.target.value)
                }
              />
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  playersMenuItems() {
    return this.props.allActivePlayers.map((player, key) => {
      return (
        <MenuItem value={player.id} key={key}>
          {player.playernumber} {player.firstname} {player.lastname}
        </MenuItem>
      );
    });
  }

  charactersAttend() {
    return this.state.gameAttend.characters.map((character, index) => {
      return (
        <TableRow key={index}>
          <TableCell>
            <FormGroup row>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={Boolean(character.characterattended)}
                    value={"ban"}
                    onChange={event =>
                      this._handleCharacterChange(
                        "characterattended",
                        event.target.checked,
                        index
                      )
                    }
                  />
                }
                label={"Attended Game"}
              />
            </FormGroup>
          </TableCell>
          <TableCell>{character.charname}</TableCell>
          <TableCell>
            <InputLabel>Referral XP</InputLabel>
            <Select
              value={character.referralxp.toString()}
              onChange={e =>
                this._handleCharacterChange("referralxp", e.target.value, index)
              }
            >
              {menuNumbers(10)}
            </Select>
          </TableCell>
          <TableCell>
            <InputLabel>Good Player Card XP</InputLabel>
            <Select
              value={character.goodplayerxp.toString()}
              onChange={e =>
                this._handleCharacterChange(
                  "goodplayerxp",
                  e.target.value,
                  index
                )
              }
            >
              {menuNumbers(10)}
            </Select>
          </TableCell>
          <TableCell>
            <InputLabel>Money Dump XP</InputLabel>
            <Select
              value={character.moneydumpxp.toString()}
              onChange={e =>
                this._handleCharacterChange(
                  "moneydumpxp",
                  e.target.value,
                  index
                )
              }
            >
              {menuNumbers(1)}
            </Select>
          </TableCell>
          <TableCell>
            <InputLabel>SP to XP</InputLabel>
            <Select
              value={character.sptoxp.toString()}
              onChange={e =>
                this._handleCharacterChange("sptoxp", e.target.value, index)
              }
            >
              {menuNumbers(2)}
            </Select>
          </TableCell>
          <TableCell>
            <InputLabel>Extra Buy XP</InputLabel>
            <Select
              value={character.extrabuyxp.toString()}
              onChange={e =>
                this._handleCharacterChange("extrabuyxp", e.target.value, index)
              }
            >
              {menuNumbers(10)}
            </Select>
          </TableCell>
        </TableRow>
      );
    });
  }

  attendanceRender() {
    return (
      <div>
        {this.playerAttend()}
        <Table className="charactersTable">
          <TableBody>{this.charactersAttend()}</TableBody>
        </Table>
      </div>
    );
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} fullWidth={true} maxWidth={"lg"}>
          {this.attendanceRender()}
          <DialogActions>
            <Button onClick={this.props.close} color="secondary">
              Cancel
            </Button>
            <Button onClick={this._onSubmit.bind(this)} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allGames: state.games.allGames,
    allActivePlayers: state.players.allActivePlayers,
    gameAttendance: state.games.gameAttendance
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchAllActivePlayers: function() {
      dispatch(fetchAllActivePlayers());
    },
    fetchGameSummary: function(gameid) {
      dispatch(fetchGameSummary(gameid));
    },
    updateAGameAttendance: function(body) {
      dispatch(updateGameAttendance(body));
    }
  };
}

GameAttendanceDialog.propTypes = {
  attendance: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired,
  gameid: PropTypes.number.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(GameAttendanceDialog);
