import React, { Component } from "react";
import { connect } from "react-redux";
import { Button, Table } from "@material-ui/core";
import TableHead from "@material-ui/core/TableHead";
import TableCell from "@material-ui/core/TableCell";
import TableRow from "@material-ui/core/TableRow";
import { getSkills, getCharacterStats, getCharacterCount, getSkillNames, getLists } from "../../api/analyticsRequests";
import { allegianceAnalytics } from "./allegiance";
import { characterStats } from "./statMetrics";
import { listAnalytics } from "./allLists";
import { allSkillsAnalytics } from "./allSkills";
import TextField from "@material-ui/core/TextField";

class Analytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      displayType: "",
      activeCharacters: 0,
      skillNames: [],
      skillValue: "",
      skillAnalytics: [],
      characterStats: [],
      allegianceAnalytics: [],
      lists: [],
      skillSearchField: ""
    };

    this.obtainCharacterCount();
    this.obtainSkillNames();
  }


  async obtainSkillNames() {
    this.setState({ skillNames: await getSkillNames() });
  }

  async obtainCharacterCount() {
    const activeChars = await getCharacterCount(6);
    if (activeChars.length > 0 && activeChars[0].hasOwnProperty("totalcharacters")) {
      this.setState({ activeCharacters: activeChars[0].totalcharacters });
    }
  }

  async getAllLists() {
    this.setState({ lists: await getLists("getmeallofthem", 6) });
  }

  onClick(type) {
    switch (type) {
      case "skills":
        break;
      case "allegiance":
        this.getAnalytics("allegiance");
        break;
      case "stats":
        this.setupCharacterStats();
        break;
      case "allSkills":
        this.getAnalytics("getmeallofthem");
        break;
      case "allLists":
        this.getAllLists();
        break;
      default:
        break;
    }
    this.setState({ displayType: type });
  }

  display() {
    switch (this.state.displayType) {
      case "allegiance":
        return allegianceAnalytics(this.state.allegianceAnalytics, this.state.activeCharacters);
      case "stats":
        return characterStats(this.state.characterStats, this.state.activeCharacters);
      case "allSkills":
        return this.constructSkillAnalytics();
      case "allLists":
        return listAnalytics(this.state.lists, this.state.activeCharacters);
      default:
        return <div/>;
    }
  }

  constructSkillAnalytics() {
    return (
      <React.Fragment>
        <TextField
          label={"search"}
          value={this.state.skillSearchField}
          onChange={(e) => this.handleTextFieldChange(e)}>
        </TextField>
        {allSkillsAnalytics(this.state.skillAnalytics, this.state.activeCharacters)}
      </React.Fragment>
    );
  }

  handleTextFieldChange(event) {
    const value = event.target.value;
    if(!value){
      this.getAnalytics("getmeallofthem");
    } else{
      this.getAnalytics(value);
    }
    this.setState({skillSearchField: value});
  }

  async setupCharacterStats() {
    this.setState({ characterStats: await getCharacterStats(6) });
  }

  async getAnalytics(skillName) {
    if (skillName === "allegiance") {
      this.setState({ allegianceAnalytics: await getSkills(skillName, 6) });
    } else {
      this.setState({ skillAnalytics: await getSkills(skillName, 6) });
    }
  }

  render() {
    return (
      <div>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                <Button variant={"contained"} color={"primary"} onClick={() => this.onClick("allSkills")}>
                  Skills
                </Button>
              </TableCell>
              <TableCell>
                <Button variant={"contained"} color={"primary"} onClick={() => this.onClick("allLists")}>
                  Lists
                </Button>
              </TableCell>
              <TableCell>
                <Button variant={"contained"} color={"primary"} onClick={() => this.onClick("allegiance")}>
                  Allegiance Members
                </Button>
              </TableCell>
              <TableCell>
                <Button variant={"contained"} color={"primary"} onClick={() => this.onClick("stats")}>
                  Stats Metrics
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
        </Table>
        {this.display()}
      </div>
    );
  }
}

export default connect(
  null, null
)(Analytics);
