import MenuItem from "@material-ui/core/MenuItem";
import React from "react";
import { postPrint } from "../api/printRequests";

export function boolDisp(bool) {
  if (bool === 0) return "false";
  else return "true";
}

export function dateToSql(date) {
  return date.toISOString().split("T")[0];
}

export function menuNumbers(max) {
  const i = [];
  let j;
  for (j = 0; j < max + 1; j++) {
    i.push(j);
  }
  return i.map((number, key) => {
    return (
      <MenuItem value={number} key={number}>
        {number}
      </MenuItem>
    );
  });
}

export async function handlePrinting(charIds) {
  let sheetsDoc = await postPrint(charIds);

  const body = await sheetsDoc.blob();

  const url = window.URL.createObjectURL(new Blob([body]));
  const a = document.createElement('a');
  a.href = url;
  a.setAttribute('download', 'CharacterSheets.xlsx');
  document.body.appendChild(a);
  a.click();
}

export function xpCosts() {
  const i = [-15, -10, -5, 0, 2, 5, 10, 15, 20, 30, 40, 50, 60, 70, 80, 90, 100];
  return i.map((number, key) => {
    return (
      <MenuItem value={number} key={number}>
        {number}
      </MenuItem>
    );
  });
}
