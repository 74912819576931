import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import MuiThemeProvider from "@material-ui/core/styles/MuiThemeProvider";
// import getMuiTheme from "@material-ui/core/styles/getMuiTheme"; --> import { createMuiTheme } from '@material-ui/core/styles'
import { grey600 } from "@material-ui/core//colors";
import { grey800 } from "@material-ui/core/colors";
import { grey900 } from "@material-ui/core/colors";
import { BrowserRouter } from "react-router-dom";

// main routes
import AppRoutes from "./js/routes";

import store from "./js/store";

// const muiTheme = getMuiTheme({
//   primary1Color: grey600,
//   primary2Color: grey900,
//   primary3Color: grey900,
//   accent1Color: grey600,
//   accent2Color: grey600,
//   accent3Color: grey600
// pickerHeaderColor: grey900,
// datePicker: {
//   headerColor: grey800,
//   selectColor: grey600
// }
// });

ReactDOM.render(
  // <MuiThemeProvider muiTheme={muiTheme}>
  <div className="dldb-ui">
    <Provider store={store}>
      <BrowserRouter>
        <AppRoutes />
      </BrowserRouter>
    </Provider>
  </div>,
  // </MuiThemeProvider>
  document.getElementById("app")
);
