import fetchJSON from "./fetchJSON";
import { queryCheck } from "../actions/validation";

export function getAllActivePlayers() {
  const options = {
    method: "GET"
  };
  return fetchJSON("/players", options).then(results => {
    return queryCheck(results);
  });
}

export function getAllActivePlayersByName(playername) {
  const options = {
    method: "GET"
  };
  return fetchJSON("/players/like?search=" + playername, options).then(
    results => {
      return queryCheck(results);
    }
  );
}

export function makeNewPlayer() {
  const options = {
    method: "GET"
  };
  return fetchJSON("/players/newplayer", options).then(results => {
    return queryCheck(results);
  });
}

export function updateAPlayer(player) {
  const options = {
    method: "POST",
    headers: {
      Accept: "application/json",
      "Content-Type": "application/json"
    },
    body: JSON.stringify(player)
  };
  return fetchJSON("/players/", options).then(results => {
    return queryCheck(results);
  });
}
