import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Button from "@material-ui/core/Button";
import DialogActions from "@material-ui/core/DialogActions";
import Dialog from "@material-ui/core/Dialog";
import { upsertSkill } from "../../reducers/skillsReducer";
import { validateSubmission } from "../../actions/validation";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TextField from "@material-ui/core/TextField";
import InputLabel from "@material-ui/core/InputLabel";
import Select from "@material-ui/core/Select";
import { menuNumbers, xpCosts } from "../../actions/commonActions";
import MenuItem from "@material-ui/core/MenuItem";
import { fetchLists } from "../../reducers/listsReducer";

class SkillDialog extends Component {
  constructor(props) {
    super(props);

    this.state = {
      skill: {
        id: props.skill.id,
        skillname: props.skill.skillname,
        listid: props.skill.listid,
        xpcost: props.skill.xpcost,
        focuscost: props.skill.focuscost,
        type: props.skill.type,
        bonushp: props.skill.bonushp,
        bonusfp: props.skill.bonusfp,
        focusrecovery: props.skill.focusrecovery,
        powerpool: props.skill.powerpool,
        strength: props.skill.strength
      }
    };

    this.props.fetchLists();
  }

  _onSubmit() {
    const reqProps = ["id", "skillname", "type", "xpcost", "listid", "strength"];
    if (
      validateSubmission(reqProps, this.state.skill) &&
      Boolean(this.state.skill.skillname) &&
      Boolean(this.state.skill.type) &&
      Boolean(this.state.skill.listid)
    ) {
      this.props.upsertSkill(this.state.skill);
      this.props.close();
    } else {
      alert("Some required fields are missing");
    }
  }

  handleChange(name, value) {
    let editedSkill = this.state.skill;
    editedSkill[name] = value;
    this.setState({ skill: editedSkill });
  }

  skill() {
    return (
      <Table className="SkillEditTable" width={"600px"}>
        <TableBody>
          <TableRow>
            <TableCell colSpan={2}>
              <TextField
                required
                label={"Skill Name"}
                value={this.state.skill.skillname}
                onChange={e => this.handleChange("skillname", e.target.value)}
              />
            </TableCell>
            <TableCell>
              <InputLabel>List</InputLabel>
              <Select
                value={this.state.skill.listid}
                onChange={e => this.handleChange("listid", e.target.value)}
              >
                {this.listsMenu()}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>XP Cost</InputLabel>
              <Select
                value={this.state.skill.xpcost}
                onChange={e => this.handleChange("xpcost", e.target.value)}
              >
                {xpCosts(20)}
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <InputLabel>Focus Cost</InputLabel>
              <Select
                value={this.state.skill.focuscost}
                onChange={e => this.handleChange("focuscost", e.target.value)}
              >
                {menuNumbers(10)}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Bonus HP</InputLabel>
              <Select
                value={this.state.skill.bonushp}
                onChange={e => this.handleChange("bonushp", e.target.value)}
              >
                {menuNumbers(20)}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Bonus FP</InputLabel>
              <Select
                value={this.state.skill.bonusfp}
                onChange={e => this.handleChange("bonusfp", e.target.value)}
              >
                {menuNumbers(20)}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Focus Recovery</InputLabel>
              <Select
                value={this.state.skill.focusrecovery}
                onChange={e =>
                  this.handleChange("focusrecovery", e.target.value)
                }
              >
                {menuNumbers(2)}
              </Select>
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell>
              <InputLabel>Power Pool</InputLabel>
              <Select
                value={this.state.skill.powerpool}
                onChange={e =>
                  this.handleChange("powerpool", e.target.value)
                }
              >
                {menuNumbers(10)}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Strength Bonus</InputLabel>
              <Select
                value={this.state.skill.strength}
                onChange={e =>
                  this.handleChange("strength", e.target.value)
                }
              >
                {menuNumbers(3)}
              </Select>
            </TableCell>
            <TableCell>
              <InputLabel>Type</InputLabel>
              <Select
                value={this.state.skill.type}
                onChange={e => this.handleChange("type", e.target.value)}
              >
                <MenuItem value={"passive"}>Passive</MenuItem>
                <MenuItem value={"skill"}>Skill</MenuItem>
              </Select>
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    );
  }

  listsMenu() {
    return this.props.allLists.map((list, index) => {
      return (
        <MenuItem value={list.id} key={index}>
          {list.name}
        </MenuItem>
      );
    });
  }

  render() {
    return (
      <div>
        <Dialog open={this.props.open} fullScreen>
          {this.skill()}
          <DialogActions>
            <Button onClick={this.props.close} color="secondary">
              Cancel
            </Button>
            <Button onClick={this._onSubmit.bind(this)} color="primary">
              Submit
            </Button>
          </DialogActions>
        </Dialog>
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allLists: state.lists.lists
  };
}

function mapDispatchToProps(dispatch) {
  return {
    upsertSkill: function(skill) {
      dispatch(upsertSkill(skill));
    },
    fetchLists: function() {
      dispatch(fetchLists());
    }
  };
}

SkillDialog.propTypes = {
  skill: PropTypes.object.isRequired,
  open: PropTypes.bool.isRequired,
  close: PropTypes.func.isRequired
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SkillDialog);
