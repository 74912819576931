
// Some packages log to the console which causes errors in IE9, unless the dev tools are open

// Service endpoints are proxied through to Nginx. Ensure that the reverse proxy
// location block exists and is configured to the right destination.
export default {
    //urlPrefix: 'https://localhost:4007',
    urlPrefix: 'https://dldbapi.strongspearstrong.com',
    requestTimeout: 15000,
    requireAuthentication: true,
    unauthorizedEndpoint: '/',
    appId: 'DeadLegends'
};