import React, { Component } from "react";
import { connect } from "react-redux";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { fetchLists, deleteList } from "../../reducers/listsReducer";
import MenuItem from "@material-ui/core/MenuItem/index";
import Button from "@material-ui/core/Button";
import Select from "@material-ui/core/Select";
import ListDialog from "./listDialog";

class ListsList extends Component {
  constructor(props) {
    super(props);
    this.props.fetchLists();
  }

  state = {
    open: false,
    blankList: {
      id: 0,
      name: "",
      type: ""
    }
  };

  listList() {
    return this.props.allLists.map((list, row) => {
      return (
        <TableRow key={row}>
          <TableCell>{list.name}</TableCell>
          <TableCell>{list.type}</TableCell>
          <TableCell>
            <Select value={""} onChange={this.handleSelect.bind(this, row)}>
              <MenuItem value="edit">Edit</MenuItem>
              <MenuItem value="delete">Delete</MenuItem>
            </Select>
          </TableCell>
        </TableRow>
      );
    });
  }

  handleSelect(selectedRow, event) {
    switch (event.target.value) {
      case "edit":
        this.setState({
          blankList: this.props.allLists[selectedRow],
          open: true
        });
        break;
      case "delete":
        if(window.confirm("Delete this list?") === true) {
          this.props.deleteList(this.props.allLists[selectedRow].id);
        }
        break;
      default:
        return alert("I should not happen");
    }
  }

  newListButton() {
    this.setState({
      blankList: {
        id: 0,
        name: "",
        type: ""
      },
      open: true
    });
  }

  render() {
    return (
      <div>
        <Table className="listsTable">
          <TableHead>
            <TableRow>
              <TableCell>List Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>
                <Button
                  onClick={this.newListButton.bind(this)}
                  variant={"contained"}
                  value={"new"}
                >
                  New List
                </Button>
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>{this.listList()}</TableBody>
        </Table>
        {this.state.open && <ListDialog
          close={() => {
            this.setState({ open: false });
          }}
          open={this.state.open}
          list={this.state.blankList}
        />}
      </div>
    );
  }
}

function mapStateToProps(state) {
  return {
    allLists: state.lists.lists
  };
}

function mapDispatchToProps(dispatch) {
  return {
    fetchLists: function() {
      dispatch(fetchLists());
    },
    deleteList: function(listid) {
      dispatch(deleteList(listid));
    }
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListsList);
