import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import ToggleButton from "@material-ui/lab/ToggleButton";
import Grid from "@material-ui/core/Grid";
import withStyles from "@material-ui/core/styles/withStyles";
import { blue, green } from "@material-ui/core/colors";

const ColorTogggleButton = withStyles({
  root: {
    "&$selected": {
      backgroundColor: blue[500]
    }
  },
  selected:{
    color: blue[500],
    backgroundColor: green[500]
  }
})(ToggleButton);

class CharacterSkillSelectDialog extends Component {
  constructor(props) {
    super(props);
  }

  skillsGrid() {
    return (
      <Grid container spacing={6}>
        {this.skillsBoxes()}
      </Grid>
    );
  }

  handleChange(key, value) {
    const currentSkills = this.props.currentSkills.map(skillItem => {
      return skillItem.skillid;
    });
    const selected = currentSkills.includes(value.skillid);

    if (selected) {
      this.props.removeSkill(currentSkills.indexOf(value.skillid));
    } else if (!selected) {
      this.props.addSkill(key);
    }
  }

  skillsBoxes() {
    return this.props.availableSkills.map((skill, key) => {
      return (
        <Grid item key={key}>
          <ColorTogggleButton
            selected={this.props.currentSkills
              .map(skillItem => {
                return skillItem.skillid;
              })
              .includes(skill.skillid)}
            value={skill}
            onChange={(e, value) => this.handleChange(key, value)}
          >
            {skill.listname} XP: {skill.skillcost} <br />
            {skill.skillname}
          </ColorTogggleButton>
        </Grid>
      );
    });
  }

  render() {
    return <div>{this.skillsGrid()}</div>;
  }
}

CharacterSkillSelectDialog.propTypes = {
  addSkill: PropTypes.func.isRequired,
  removeSkill: PropTypes.func.isRequired,
  currentSkills: PropTypes.array.isRequired,
  availableSkills: PropTypes.array.isRequired
};

export default connect(
  null,
  null
)(CharacterSkillSelectDialog);
